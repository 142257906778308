import React, { useRef } from 'react';
import useOnScreen from './utils/useOnScreen';
import { Arrow } from '../../assets/icons/iconIndex';
import { useNavigate } from 'react-router-dom';
import config from '../../API/configaration';

const MajorIndustryItem = ({ item }) => {
  const navigate = useNavigate()
  const ref = useRef();
  const isVisible = useOnScreen(ref, { threshold: 0.1 });
  const handleItemClick = (id) => {
    navigate(`/industries/${id}`)
  }
  return (
    <div
      ref={ref} // Attach the ref to the div
      className={`major-industries-div ${isVisible ? 'aos-animate' : 'aos-init'}`}
    >
      <div className="major-industries-image-div">
        <img src={config.apiBaseUrl + item.image} alt="" />
      </div>
      <div className="major-industries-description">
        <div className="small-heading">{item.title}</div>
        <p className='small-text'>{item.card_content}</p>
        <div className="read-more-button" onClick={() => handleItemClick(item.path)}>
          Read More <Arrow/>
        </div>
      </div>
    </div>
  );
};

export default MajorIndustryItem;
