import React, { useState } from 'react'
import './solution-header-menu.css';
import { useNavigate } from 'react-router-dom';
function SolutionHeaderMenu({isOpen,handleClose}) {
    const navigation = useNavigate()
        const handleCloses = () =>{
            console.log("handle");
            
            handleClose();
        }
        const handleNavigation = (pathnName) => {
            handleClose()
            navigation(`solutions/${pathnName}`)
        }
  return (
//     <div className="solutions-submenu">
//         <div className="submenu-item" onClick={() => setIsFireMenuOpen(!isFireMenuOpen)}>
//         <span>Fire & Detection</span> <span className={` material-icons ${isFireMenuOpen ? 'rotate' : 'revert'}`}>arrow_drop_down</span>
//         </div>
//         <div className={`fire-detection-sub-menu ${isFireMenuOpen ? 'open' : 'close'}`}>
//         <div className="submenu-item" onClick={() => naviagte(`solutions/fire-detection-system`)}>Fire Detection System</div>
//         <div className="submenu-item" onClick={() => naviagte(`solutions/fire-alarm-and-signalling`)}>Fire Alarm and Signalling</div>
//         <div className="submenu-item" onClick={() => naviagte(`solutions/flame-and-gas-detection`)}>Flame and Gas Detection</div>
//         <div className="submenu-item" onClick={() => naviagte(`solutions/control-panel`)}>Fire & Gas Control Panel</div>
//         </div>
//         <div className="submenu-item" onClick={() => naviagte(`solutions/high-pressure-water-mist-system`)}>High-Pressure Water Mist System</div>
//         <div className="submenu-item" onClick={() => naviagte(`solutions/halocarbon-fire-suppression-system`)}>Halocarbon Suppression System</div>
//         <div className="submenu-item" onClick={() => naviagte(`solutions/inert-gas-suppression-system`)}>Inert Gas Suppression System</div>
//         <div className="submenu-item" onClick={() => naviagte(`solutions/co2-suppression-system`)}>CO2 Suppression System</div>
//         <div className="submenu-item" onClick={() => naviagte(`solutions/foam-based-fire-suppression-system`)}>Foam Suppression System</div>
//         <div className="submenu-item" onClick={() => naviagte(`solutions/cctv-surveillance-systems`)}>CCTV Surveillance systems</div>


//     {/* {solutionList?.map((item,index) =>(
//       <div className="submenu-item" onClick={() => naviagte(`solutions/${item.path}`)} key={index}>{item.title}</div>
//     ))} */}
//   </div>
    // <div className="solutions-submenu">
    //     <div className="submenu-item submemu-item-div ">
    //         <span>Fire & Gas</span><span className='material-icons'>arrow_drop_down</span>
    //         <div className="submenu-container">
    //             <div className="submenu-item" onClick={() => setIsFireMenuOpen(!isFireMenuOpen)}>
    //             <span>Fire & Detection</span> <span className={` material-icons ${isFireMenuOpen ? 'rotate' : 'revert'}`}>arrow_drop_down</span>
    //             </div>
    //             <div className={`fire-detection-sub-menu ${isFireMenuOpen ? 'open' : 'close'}`}>
    //                 <div className="submenu-item" onClick={() => naviagte(`solutions/fire-detection-system`)}>Fire Detection System</div>
    //                 <div className="submenu-item" onClick={() => naviagte(`solutions/fire-alarm-and-signalling`)}>Fire Alarm and Signalling</div>
    //                 <div className="submenu-item" onClick={() => naviagte(`solutions/flame-and-gas-detection`)}>Flame and Gas Detection</div>
    //                 <div className="submenu-item" onClick={() => naviagte(`solutions/control-panel`)}>Fire & Gas Control Panel</div>
    //             </div>
    //             <div className="submenu-item" onClick={() => naviagte(`solutions/high-pressure-water-mist-system`)}>High-Pressure Water Mist System</div>
    //             <div className="submenu-item" onClick={() => naviagte(`solutions/halocarbon-fire-suppression-system`)}>Halocarbon Suppression System</div>
    //             <div className="submenu-item" onClick={() => naviagte(`solutions/inert-gas-suppression-system`)}>Inert Gas Suppression System</div>
    //             <div className="submenu-item" onClick={() => naviagte(`solutions/co2-suppression-system`)}>CO2 Suppression System</div>
    //             <div className="submenu-item" onClick={() => naviagte(`solutions/foam-based-fire-suppression-system`)}>Foam Suppression System</div>
    //             <div className="submenu-item" onClick={() => naviagte(`solutions/cctv-surveillance-systems`)}>CCTV Surveillance systems</div>
    //         </div>
    //     </div>
    //     <div className="submenu-item submemu-item-div">
    //         <span>Electrical & Automation</span><span className='material-icons'>arrow_drop_down</span>
    //         <div className="submenu-container">
    //             <div className="submenu-item">Drill Monitoring System</div>
    //             <div className="submenu-item">Flare Boom Ignition System</div>
    //             <div className="submenu-item">LED Conversion Upgrades</div>
    //             <div className="submenu-item">Emergency Shutdown System</div>
    //             <div className="submenu-item">Bulk Weight Monitoring System</div>
    //             <div className="submenu-item">Drilling CCTV Systems</div>
    //             <div className="submenu-item">Power Management System</div>
    //             <div className="submenu-item">Jacking Control System</div>
    //             <div className="submenu-item">VFD Control System</div>
    //             <div className="submenu-item">Bilge Monitoring & Control System</div>
    //             <div className="submenu-item">Talkback and PA/GA systems</div>
    //             <div className="submenu-item">Rack Phase Differential System</div>
    //             <div className="submenu-item">Watertight Door Monitoring System</div>
    //             <div className="submenu-item">Vessel Management System</div>
    //             <div className="submenu-item">SCR Control System</div>
    //             {/* <div className="submenu-item"></div> */}
    //         </div>
    //     </div>
    //     <div className="submenu-item submemu-item-div">
    //         <span>Mechanical Service</span><span className='material-icons'>arrow_drop_down</span>
    //         <div className="submenu-container">
    //             <div className="submenu-item">Oil & Gas Equipment</div>
    //             <div className="submenu-item">Marine Equipment</div>
    //             <div className="submenu-item">Other Industrial Equipment</div>
    //         </div>       
    //     </div>
    // </div>
    // <div className="solutions-submenu active">
    //     <div className="submenu-column">
    //         <div className="submenu-heading">Fire & Gas</div>
    //         <div className="submenu-item">
    //             <span>Fire & Detection</span>
    //             <div className="submenu-item">Fire Detection System</div>
    //             <div className="submenu-item">Fire Alarm and Signalling</div>
    //             <div className="submenu-item">Flame and Gas Detection</div>
    //             <div className="submenu-item">Fire & Gas Control Panel</div>
    //         </div>
    //         <div className="submenu-item">High-Pressure Water Mist System</div>
    //         <div className="submenu-item">Halocarbon Suppression System</div>
    //         <div className="submenu-item">Inert Gas Suppression System</div>
    //         <div className="submenu-item">CO2 Suppression System</div>
    //         <div className="submenu-item">Foam Suppression System</div>
    //         <div className="submenu-item">CCTV Surveillance systems</div>
    //     </div>
    //     <div className="submenu-column">
    //         <div className="submenu-heading">Electrical & Automation</div>
    //         <div className="submenu-item">Drill Monitoring System</div>
    //         <div className="submenu-item">Flare Boom Ignition System</div>
    //         <div className="submenu-item">LED Conversion Upgrades</div>
    //         <div className="submenu-item">Emergency Shutdown System</div>
    //         <div className="submenu-item">Bulk Weight Monitoring System</div>
    //         <div className="submenu-item">Drilling CCTV Systems</div>
    //         <div className="submenu-item">Power Management System</div>
    //         <div className="submenu-item">Jacking Control System</div>
    //         <div className="submenu-item">VFD Control System</div>
    //         <div className="submenu-item">Bilge Monitoring & Control System</div>
    //         <div className="submenu-item">Talkback and PA/GA systems</div>
    //         <div className="submenu-item">Rack Phase Differential System</div>
    //         <div className="submenu-item">Watertight Door Monitoring System</div>
    //         <div className="submenu-item">Vessel Management System</div>
    //         <div className="submenu-item">SCR Control System</div>
    //     </div>        
    //     <div className="submenu-column">
    //         <div className="submenu-heading">Mechanical Service</div>
    //         <div className="submenu-item">Oil & Gas Equipment</div>
    //         <div className="submenu-item">Marine Equipment</div>
    //         <div className="submenu-item">Other Industrial Equipment</div>
    //     </div>
    // </div>
    <>
        <div className={`solutions-submenu ${isOpen ? 'active' : ''}`}>
            <div className="submenu-column">
                <div className="submenu-heading"> Fire & Gas Solutions</div>
                <div className="submenu-row">
                    <div className="submenu-column">
                        <div className="submenu-item">
                            <span>Fire & Gas Detection</span>
                            <div className="submenu-item" onClick={()=> handleNavigation('fire-detection-system')}>Fire Detection System</div>
                            <div className="submenu-item" onClick={()=> handleNavigation('fire-alarm-and-signalling')}>Fire Alarm and Signalling</div>
                            <div className="submenu-item" onClick={()=> handleNavigation('flame-and-gas-detection')}>Flame & Gas Detection</div>
                            <div className="submenu-item" onClick={()=> handleNavigation('control-panel')}>Fire & Gas Control Panel</div>
                        </div>
                        <div className="submenu-item" onClick={()=> handleNavigation('high-pressure-water-mist-system')}>High-Pressure Water Mist System</div>
        
                    </div>
                    <div className="submenu-column">
                        <div className="submenu-item" onClick={()=> handleNavigation('halocarbon-fire-suppression-system')}>Halocarbon Suppression System</div>
                        <div className="submenu-item" onClick={()=> handleNavigation('inert-gas-suppression-system')}>Inert Gas Suppression System</div>
                        <div className="submenu-item" onClick={()=> handleNavigation('co2-suppression-system')}>CO2 Suppression System</div>
                        <div className="submenu-item" onClick={()=> handleNavigation('foam-based-fire-suppression-system')}>Foam Suppression System</div>
                    </div>
                </div>
            </div>
            <div className="submenu-column">
                <div className="submenu-heading">Other Solutions</div>
                <div className="submenu-row">
                    <div className="submenu-column">
                        <div className="faded-heading-submenu" style={{marginTop:'0px'}}>Electrical & Automation</div>
                        <div className="submenu-item" onClick={()=> handleNavigation('drill-monitoring-system')}>Drill Monitoring System</div>
                        <div className="submenu-item" onClick={()=> handleNavigation('flare-boom-ignition-system')}>Flare Boom Ignition System</div>
                        <div className="submenu-item" onClick={()=> handleNavigation('led-conversion-upgrades')}>LED Conversion Upgrades</div>
                        <div className="submenu-item" onClick={()=> handleNavigation('emergency-shutdown-system')}>Emergency Shutdown System</div>
                        <div className="submenu-item" onClick={()=> handleNavigation('bulk-weight-monitoring-system')}>Bulk Weight Monitoring System</div>
                        {/* <div className="submenu-item" onClick={()=> handleNavigation('drilling-cctv-systems')}>Drilling CCTV Systems</div> */}
                        <div className="submenu-item" onClick={()=> handleNavigation('cctv-surveillance-systems')}>CCTV Surveillance systems</div>
                    </div>
                    <div className="submenu-column">
                        <div className="submenu-item" onClick={()=> handleNavigation('power-management-system')}>Power Management System</div>
                        <div className="submenu-item" onClick={()=> handleNavigation('jacking-control-system')}>Jacking Control System</div>
                        <div className="submenu-item" onClick={()=> handleNavigation('vfd-control-system')}>VFD Control System</div>
                        <div className="submenu-item" onClick={()=> handleNavigation('bilge-monitoring-control-system')}>Bilge Monitoring & Control System</div>
                        <div className="submenu-item" onClick={()=> handleNavigation('talkback-and-pa-ga-systems')}>Talkback and PA/GA systems</div>
                        <div className="submenu-item" onClick={()=> handleNavigation('rack-phase-differential-system')}>Rack Phase Differential System</div>
                        <div className="submenu-item" onClick={()=> handleNavigation('watertight-door-monitoring-system')}>Watertight Door Monitoring System</div>

                    </div>
                    <div className="submenu-column">
                        <div className="submenu-item" onClick={()=> handleNavigation('vessel-management-system')}>Vessel Management System</div>
                        <div className="submenu-item" onClick={()=> handleNavigation('scr-control-system')}>SCR Control System</div>
                        <div className="faded-heading-submenu">Mechanical Services</div>
                        <div className="submenu-item" onClick={()=> handleNavigation('oil-gas-equipment')}>Oil & Gas Equipment</div>
                        <div className="submenu-item" onClick={()=> handleNavigation('marine-equipment')}>Marine Equipment</div>
                        <div className="submenu-item" onClick={()=> handleNavigation('other-industrial-equipment')}>Other Industrial Equipment</div>
                    </div>
                </div>
            </div>
            {/* <div className="submenu-column">
                <div className="submenu-heading">Mechanical Service</div>

            </div> */}
        </div>
        {isOpen && 
           <div onClick={handleClose} className="solution-submenu-overlay"></div>     
        }
    </>

  )
}

export default SolutionHeaderMenu