// import React, { useEffect } from 'react'
// import './scroll-up-button.css'
// import { useGeneralContext } from '../../../GeneralContext'
// import { ArrowUp } from '../../../assets/icons/iconIndex';

// function ScrollUpButton() {
//   const {isScrollToTopVisible} = useGeneralContext()
//   const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: 'smooth',
//     });
//   };


//   return (
//     <div className={`arrow-up-navigation ${isScrollToTopVisible ? 'show' : 'hide'}`} onClick={scrollToTop}><ArrowUp/></div>
//   )
// }

// export default ScrollUpButton

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './scroll-up-button.css';
import { ArrowUp } from '../../../assets/icons/iconIndex';

function ScrollUpButton() {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation(); // Track route changes

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    let observer;

    const updateVisibility = (entries) => {
      const anyElementVisible = entries.some((entry) => entry.isIntersecting);
      setIsVisible(!anyElementVisible);
    };

    const initializeObserver = () => {
      observer = new IntersectionObserver(updateVisibility, {
        root: null, // Entire viewport
        threshold: 0.1, // At least 10% visible
      });

      const scrollRefElements = document.querySelectorAll('.scroll-ref');
      if (scrollRefElements.length === 0) {
        setIsVisible(true); // If no `.scroll-ref`, show the button
      } else {
        scrollRefElements.forEach((el) => observer.observe(el));
      }
    };

    const disconnectObserver = () => {
      if (observer) {
        observer.disconnect();
      }
    };

    // Reinitialize observer on mount or route change
    initializeObserver();

    return () => {
      disconnectObserver();
    };
  }, [location]); // Re-run whenever the route changes

  return (
    <div
      className={`arrow-up-navigation ${isVisible ? 'show' : 'hide'}`}
      onClick={scrollToTop}
    >
      <div className='arrow-icon-container'>
        <div className="material-icons">arrow_upward</div>
      </div>
      {/* <ArrowUp/> */}
    </div>
  );
}

export default ScrollUpButton;
