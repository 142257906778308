// import React, { useEffect, useState } from 'react'
// import { Mail , ArrowRigth2 ,Location, Send, Whatsapp } from '../../../assets/icons/iconIndex';
// import GoogleMapEmbed from '../carousel/GoogleMap';
// import MapMedium from '../Maps/MapMedium';
// import './contactSection.css'
// import { letsTalk } from '../../../API/services';
// import { notifySuccess } from '../utils/ToastProvider';
// function ContactSection() {
//     const [letsTalkValues,setLetsTalkValues] = useState({
//         name:'',
//         email:'',
//         phone:'',
//         message:''
//     })
//      const [error,setError] = useState({})
//      const [email,setEmail] = useState()
//      const [screenWidth,setScreenWidth] = useState()
//      useEffect(() => {
//         const handleResize = () => {
//             setScreenWidth(window.innerWidth)
//             // setIsSmallScreen(window.innerWidth < 1200);
//         };
//         handleResize();
//         window.addEventListener('resize', handleResize);
//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);
//      const validatePhone = (phone) => {
//         const phoneRegex = /^\+?\d+$/;

//         if (phoneRegex.test(phone)) {
//             return true;
//         } else {
//             return false;
//         }
//     };
//     const validateEmail = (email) => {
//         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//         return emailRegex.test(email);
//     };
//     const handleChange = (event) => {
//         const { name, value } = event.target;
//         setLetsTalkValues({
//           ...letsTalkValues,
//           [name]: value
//         });
//         setError(prevError => ({ ...prevError, [name]: undefined }));
//       };
//     const letsTalkSubmit = () =>{
//         let validationError = {}
//         if(!letsTalkValues.name){
//             validationError.name = "Please Enter You Name"
//         }
//         if(!letsTalkValues.email){
//             validationError.email = "Please Enter Your  Email"
//         } else if(!validateEmail(letsTalkValues.email)){
//             validationError.email = "Please Enter A Valid Email"
//         }
//         if (!letsTalkValues.phone) {
//             validationError.phone = "Please Enter Your Phone Number";
//         } else if (!validatePhone(letsTalkValues.phone)) {
//             validationError.phone = "Please Enter A Valid Phone Number";
//         }
//         setError(validationError)
//         if(Object.keys(validationError).length === 0){
//             const formData = new FormData();
//             formData.append('name', letsTalkValues.name);
//             formData.append('email', letsTalkValues.email);
//             formData.append('phone',letsTalkValues.phone);
//             formData.append('message',letsTalkValues.message);
//             letsTalk(formData).then((res)=>{
//                 setLetsTalkValues({
//                     name:'',
//                     email:'',
//                     phone:'',
//                     message:''
//                 })
//                 notifySuccess('Your request has been submitted successfully.')
//             }).catch((error)=>{
//                 console.log(error);
                
//             })
//         }
//     }
//     const emailSubmit = () => {
//         let emailError = {};
//         if (!email) {
//             emailError.subscribeEmail = 'Please Enter Your Email';
//         } else if (!validateEmail(email)) {
//             emailError.subscribeEmail = 'Please Enter A Valid Email';
//         }
//         setError(prevError => ({ ...prevError, ...emailError }));
//     };
//   return (
//     <>
//         <section className="talk-section" >
//             <div className='container'>
//                 <div className="talk-box" >
//                     <div className="talk-box-input-section">
//                         <h3 className="fade-heading">Locate Us</h3>
//                         <h1 style={{color:'white'}} className="large-heading">Let's Talk</h1>
//                         <div className="input-div">
//                             <div className='wf-100'>
//                                 <input name='name' value={letsTalkValues.name} onChange={handleChange} type="text" placeholder="Full Name*"/>
//                                 {error.name && <span className='error-message'>*{error.name}</span>}
//                             </div>
//                             <div className='wf-50'>
//                                 <input name='email' value={letsTalkValues.email} onChange={handleChange} type="email" placeholder='Email*' />
//                                 {error.email && <span className='error-message'>*{error.email}</span>}
//                             </div>
//                             <div className='wf-50'>
//                                 <input name='phone' value={letsTalkValues.phone} onChange={handleChange} type="text" placeholder='Phone No*' />
//                                 {error.phone && <span className='error-message'>*{error.phone}</span>}
//                             </div>
//                             <div className='wf-50'>
//                                 <textarea style={{height:'100px'}} name='message' value={letsTalkValues.message} onChange={handleChange} type='text' placeholder='Message'/>
//                             </div>
//                             <div className="wf-50 submit-div"><button className='submit-button' onClick={letsTalkSubmit}>Submit <ArrowRigth2 style={{fill:'white'}}/></button></div>
//                         </div>
//                     </div>
//                     <div className="contact-support-section">
//                         <h3 className="heading">Contact Support</h3>
//                         <div className="contact-details-div">
//                             <div className="contact"><Whatsapp/><span> +65 6659 8168</span></div>
//                             <div className="contact"><Mail/><span> sales@hawkmarine.com.sg</span></div>
//                             <div className="contact"><Location/><span>50 Tuas Ave 11, #02-32 Tuas Lot,<br /> Singapore - 639107</span></div>


//                         </div>
//                         {/* <div className="subscribe-div">
//                             <h2 className="heading">Subscribe</h2>
//                             <div class="email-input">
//                                 <input value={email} onChange={(event)=> setEmail(event.target.value)} type="email" placeholder="Email" />
//                                 <Send onClick={emailSubmit}/>
//                             </div>
//                             {error.subscribeEmail && <span className='error-message'>*{error.subscribeEmail}</span>}
//                         </div> */}
//                     </div>
//                 </div>
//             </div>
//             {screenWidth >= 900 && (
//         <GoogleMapEmbed/> 
//         )}
//         {screenWidth < 900 && (
//             <MapMedium/>
//         )}
//         </section>

//     </>
//   )
// }

// export default ContactSection


import React, { useEffect, useState } from 'react'
import { Mail ,Location, Whatsapp } from '../../../assets/icons/iconIndex';
import GoogleMapEmbed from '../carousel/GoogleMap';
import MapMedium from '../Maps/MapMedium';
import './contactSection.css'
import { useGeneralContext } from '../../../GeneralContext';
function ContactSection() {
     const {contactList} = useGeneralContext()
     const [screenWidth,setScreenWidth] = useState()
     useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
            // setIsSmallScreen(window.innerWidth < 1200);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

  return (
    <>
        <section className="talk-section" >
            <div className='container'>
                <div className="talk-box" >
                    <div className="talk-box-first-part">
                        <h3 className="fade-heading">Locate Us</h3>
                        <h1 style={{color:'white'}} className="large-heading">Let's Talk</h1>
                    </div>
                    <div className="talk-box-second-part">
                        {contactList?.map((item,index)=>(
                            <div className="talk-box-second-item" key={index}>
                                <Location/>
                                <div className="item-container">
                                    <div className='item-location'>{item.title}</div>
                                    <span>{item.country}</span>
                                </div>
                            </div>
                        ))}

                    </div>
                    <div className="contact-support-section">
                        <h3 className="heading">Contact Support</h3>
                        <div className="contact-details-div">
                            <div className="contact"><Whatsapp/><span> +65 6659 8168</span></div>
                            <div className="contact"><Mail/><span> sales@hawkmarine.com.sg</span></div>
                            <div className="contact"><Location/><span>50 Tuas Ave 11, #02-32 Tuas Lot,<br /> Singapore - 639107</span></div>


                        </div>
                        {/* <div className="subscribe-div">
                            <h2 className="heading">Subscribe</h2>
                            <div class="email-input">
                                <input value={email} onChange={(event)=> setEmail(event.target.value)} type="email" placeholder="Email" />
                                <Send onClick={emailSubmit}/>
                            </div>
                            {error.subscribeEmail && <span className='error-message'>*{error.subscribeEmail}</span>}
                        </div> */}
                    </div>
                </div>
            </div>
            {screenWidth >= 900 && (
        <GoogleMapEmbed/> 
        )}
        {screenWidth < 900 && (
            <MapMedium/>
        )}
        </section>

    </>
  )
}

export default ContactSection