import React, { useRef } from 'react'
import ContactSection from '../Common/contact-section/ContactSection'
import '../../assets/css/contact-us.css';
import { Location } from '../../assets/icons/iconIndex';
import useOnScreen from '../Common/utils/useOnScreen';
import { useGeneralContext } from '../../GeneralContext';
function ContactUs() {

  const {contactList} = useGeneralContext()
  // animation
  const divRef1 = useRef();
  const isVisible1 = useOnScreen(divRef1);
  return (
    <>
    <div className='contact-us-container scroll-ref'>
      <div className={`address-card-container ${isVisible1 ? 'aos-animate' : 'aos-init'}`} ref={divRef1}>
        {contactList?.map((item,index)=>(
        <div className="address-card" key={index}>
          <div className="address-icon">
            <Location />
          </div>
          <div className="address-details">
            <h3 className="address-title">{item.title}</h3>
            <p>{item.line1}</p>
            <p>{item.line2}</p>
            <p>{item.city}, {item.postcode}</p>
            <p>{item.country}</p>
          </div>
        </div>
        ))}
      </div>
        <ContactSection />
    </div>
    </>
  )
}

export default ContactUs