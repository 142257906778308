import React, { useEffect, useRef, useState } from 'react';
import './cardGroup.css';
import { capitalizeFirstLetter } from '../utils/textUtils';
import { ArrowLeft, ArrowRight } from '../../../assets/icons/iconIndex';
import { getSolutionList } from '../../../API/services';
import config from '../../../API/configaration';
function CardGroup({ onCardClick }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const cardGroupsRef = useRef(null);
    const intervalRef = useRef(null);
    const [isTransitionEnabled, setIsTransitionEnabled] = useState(true); // New state for transition control
    const [data,setData] = useState([
        {}
    ])
    useEffect(() => {
        startAutoScroll();
        return () => stopAutoScroll(); // Cleanup auto-scroll
    }, [data.length]);

    const fetchData = async () => {
        const response = await fetch('/assets/json/solution.json');
        const datas = await response.json();
        setData(datas.solution[0].slice(0,9))
      };
    useEffect(() => {
        const container = cardGroupsRef.current;
        if (container) {
            const cardWidth = container.firstChild.clientWidth; // Get the width of one card
            const offset = -currentIndex * cardWidth; // Adjust the offset by card width

            container.style.transform = `translateX(${offset}px)`;
            container.style.transition = isTransitionEnabled ? 'transform 1s ease-in-out' : 'none'; // Apply or remove transition
        }
    }, [currentIndex, isTransitionEnabled]);
    
    useEffect(()=>{
        fetchData();
        getSolutionList().then((res)=>{
            const responce = res.data.slice(0,9).map(item => ({
                ...item,
                image: config.apiBaseUrl + item.image,
              }));
            setData(responce)
        }).catch((error)=>{

        })
    },[])
    const startAutoScroll = () => {
        intervalRef.current = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
            setIsTransitionEnabled(true); // Enable transition on auto-scroll
        }, 2000); // Switch every 10 seconds
    };

    const stopAutoScroll = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    };

    const handleNextClick = () => {
        stopAutoScroll();

        if (currentIndex === data.length - 1) {
            // If it's the last item, disable transition for the wrap-around
            setIsTransitionEnabled(false);
        } else {
            setIsTransitionEnabled(true); // Enable transition for normal clicks
        }

        setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    };

    const handlePrevClick = () => {
        stopAutoScroll();

        if (currentIndex === 0) {
            // If it's the first item, disable transition for the wrap-around
            setIsTransitionEnabled(false);
        } else {
            setIsTransitionEnabled(true); // Enable transition for normal clicks
        }

        setCurrentIndex((prevIndex) => 
            prevIndex === 0 ? data.length - 1 : prevIndex - 1
        );
    };

    return (
        <div className="card-group-div">
            <div className="card-groups" ref={cardGroupsRef}>
                {[...data,...data].map((item, index) => (
                    <div
                        className="solution-card"
                        key={index}
                        style={{
                            backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0) 100%), url(${item.image})`,
                        }}
                    >
                        <div className='solution-card-content'>
                            <div className="solution-card-heading">
                                <div className="card-heading">{item.title}</div>
                            </div>
                            <div className="card-description">
                                {item.card_content?.split('<br>').map((des,k) =>(
                                <div className="card-description-text" key={k}>{capitalizeFirstLetter(des.trim())}</div>
                                ))}
                            </div>
                            {item.points && item.points.length > 0 && (
                                <ul className="card-points">
                                    {item.points.split(',').map((point, i) => (
                                        <li className="card-points-item" key={i}>
                                            {point.trim()}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {item.additionalDescription && (
                                <div className="card-description">
                                    <div className="card-description-text">
                                        {capitalizeFirstLetter(item.additionalDescription)}
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="card-footer">
                            <button className="card-button" onClick={() => onCardClick(item.path)}>Learn More</button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="navigation-bar-cards">
                <ArrowLeft onClick={handlePrevClick} />
                <ArrowRight onClick={handleNextClick} />
            </div>
        </div>
    );
}

export default CardGroup;
