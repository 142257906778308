import React, { useRef } from 'react'
import useOnScreen from './utils/useOnScreen';
import config from '../../API/configaration';

function ServiceItem({item}) {
    const ref = useRef();
    const isVisible = useOnScreen(ref, { threshold: 0.1 });

    const handleItemClick = () => {

    }
  return (
    <div
    ref={ref} // Attach the ref to the div
    className={`card2 ${isVisible ? 'aos-animate' : 'aos-init'}`}
    >
        <div className="card2-img-div">
            <img className='card2-img' src={config.apiBaseUrl + item?.image} alt="error" />
        </div>
        <div className="card2-content" dangerouslySetInnerHTML={{__html: item?.content}}></div>
    </div>
  )
}

export default ServiceItem