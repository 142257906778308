import axiosInstance from "./axiosInstance";

export const getPageHeaderWithName = (pageName) => {
    return axiosInstance.get(`v1/pages/?page_name=${pageName}`)
}
export const getCarouselData = () => {
    return axiosInstance.get("/v1/carousel/")
}
export const getIndustriesData = () => {
    return axiosInstance.get(`v1/industry/`)
}
export const getSolutionList = () => {
    return axiosInstance.get(`v1/products/`)
}
export const getCompanyList = () => {
    return axiosInstance.get(`v1/companies/?limit=100`)
}
export const letsTalk = (data) => {
    return axiosInstance.post('formData/v1/contact-us/',data)
}
export const getReferenceList = (year) => {
    return axiosInstance.get(`v1/reference/${year ? `?year=${year}` : ''}`)
}
// export const getReferenceList = (year) => {
//     return axiosInstance.get(`v1/reference/?pagination=false`)
// }
export const getSettings = () => {
    return axiosInstance.get('v1/settings/')
}