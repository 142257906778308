import React from 'react'

function MapMedium() {
  return (
    <iframe 
        id='google-map'
        title="Google Map of Hawk Marine PTE LTD"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7512956024952!2d103.64007547472454!3d1.3251284986623078!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da05f928e82297%3A0xb0ac57addfc0996b!2sHAWK%20MARINE%20PTE%20LTD!5e0!3m2!1sen!2sin!4v1727762370307!5m2!1sen!2sin" 
        width="100%" 
        height="450"
        style={{ border: 0 ,filter: 'grayscale(100%) invert(90%)',marginBottom:'-6px',height:'450px' }}
        allowFullScreen="" 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade">
    </iframe>
  )
}

export default MapMedium