import React, { useEffect, useState } from 'react';
import logo from '../assets/image/Group430.png';
import { CustomerService, Facebook, Instagram, LinkedIn, X, Youtube } from '../assets/icons/iconIndex';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuButton from './Common/Buttons/MenuButtons';
import { getSettings, getSolutionList } from '../API/services';
import { useGeneralContext } from '../GeneralContext';
import SolutionHeaderMenu from './Common/solution-heder-menu/SolutionHeaderMenu';
function Header() {
    const [scrolled, setScrolled] = useState(false);
    const [activePath, setActivePath] = useState('/');
    const [isMobile,setIsMobile] = useState(true);
    const [isMenuOpen,setIsMenuOpen] = useState(false);
    const [menuItemsStatus,setMenuItemsStatus] = useState({
      fire_gas:false,author:false,mechanical:false
    })
    const [isSubMenuOpen,setIsSubMenuOpen] = useState(false)
    const {settings,setSettings} = useGeneralContext()
    const [solutionList,setSolutionsList] = useState([
      {path:'fire-and-gas-detection-system',title:'Fire & Gas Detection System'},
      {path:'high-pressure-water-mist-system',title:"High-Pressure Water Mist System"},
      {path:'halocarbon-clean-agent-fire-suppression-system',title:"Halocarbon Clean Agent Fire Suppression System"},
      // {path:'foam-based-fire-suppression-system',title:"Foam-based Fire Suppression System"},
      // {path:'inner-gas-suppression-system',title:"Inert Gas Suppression System"},
      // {path:'cctv-system',title:"CCTV System"},
    ])
    const location = useLocation();
    const naviagte = useNavigate()
    useEffect(() => {
      const handleScroll = () => {
        const isScrolled = window.scrollY > 0;
        setScrolled(isScrolled);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    useEffect(() => {
      setActivePath(location.pathname);
  }, [location]);

  useEffect(() => {
    console.log(window.scrollY,"window");
    
    if (isSubMenuOpen && window.scrollY === 0) {
      
      window.scrollTo({
        top: 1, 
        behavior: 'smooth',
      });
    }
  }, [isSubMenuOpen]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
      if(window.innerWidth > 900){
        setIsMenuOpen(false)
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const togglePopup = () =>{
    setIsMenuOpen(!isMenuOpen);
  }

  const handleMenuItemClick = (route) => {
    naviagte(route)
    if(isMobile){
      setIsMenuOpen(false)
    }else{
      setIsSubMenuOpen(false)
    }
    
  }

  useEffect(() => {
    if (isMenuOpen) {
        document.body.classList.add('no-scroll');
    } else {
        document.body.classList.remove('no-scroll');
    }
    return () => {
        document.body.classList.remove('no-scroll');
    };
}, [isMenuOpen]); 

useEffect(() => {
  if (isSubMenuOpen && !isMobile) {
      document.body.classList.add('no-scroll');
  } else {
      document.body.classList.remove('no-scroll');
  }
  return () => {
      document.body.classList.remove('no-scroll');
  };
}, [isSubMenuOpen]);
  useEffect(()=>{
    getSolutionList().then((res)=>{
      setSolutionsList(res.data)
    }).catch((error)=>{

    })
    getSettings().then((res)=>{
      setSettings(res.data[0])
    }).catch((error)=>{
      
    })
  },[])

  const handleSubMenuItemClick = (menuItem) => {
    setMenuItemsStatus((prevState) => ({
      ...prevState,
      [menuItem]: !prevState[menuItem],
  }));
};

  return (
    <>
    {isMobile ? (
      <div className='mobile-header-div'>
          <div className='mobile-header-sub-div'>
            <div className="header-logo-container" onClick={()=>naviagte('/')}>
              <img className='header-logo-img' src={logo} alt="" />
            </div>
          </div>
          <div className='mobile-menu-button-div'><MenuButton menuVisible={isMenuOpen} onClick={togglePopup}/></div>
      </div>
    ):(
      <header className="header-container">
          <div className={`header ${scrolled? 'scrolled' : ''}`}>
              <div className='nav-bar-container'>
                  <div className="main-log-container" onClick={()=>naviagte('/')}><img className='main-logo-img' src={logo} alt="" /></div>
                  <div className="nav-bar-part-two">
                      <div className="top-bar">
                          <div className="contact-info">
                              <div><span className='material-icons'>access_time</span><span>Mon - Sat 9:00am - 6:00pm</span></div>
                              <div>
                                <span className='material-icons'>email</span>
                                <span>
                                  <a href={`mailto:${settings?.email ? settings.email : 'sales@hawkmarine.com.sg'}`}>
                                    {settings?.email ? settings.email : 'sales@hawkmarine.com.sg'}
                                  </a>
                                </span>
                              </div>
                          </div>
                          <div className="social-media-links">
                            {/* <X/> */}
                            {/* <Facebook/> */}
                            {/* <Instagram/> */}
                            <a style={{padding:'0px'}} href='https://www.linkedin.com/company/hawk-marine/' target="_blank" rel="noopener noreferrer"><LinkedIn/></a>
                            {/* <Youtube/> */}
                          </div>
                      </div>
                      <div className="bottom-bar">
                          <div className="nav-menu">
                                  <div onClick={() => handleMenuItemClick('/')} className={`linkBttn ${activePath === '/' ? 'active':''}`}>Home</div>
                                  <div onClick={() => handleMenuItemClick('/about')} className={`linkBttn ${activePath.includes('about') ? 'active':''}`}>About</div>
                                  <div onClick={()=>setIsSubMenuOpen(!isSubMenuOpen)} className='solution-header'>
                                    <div className={`linkBttn ${activePath.includes('/solutions') || isSubMenuOpen ? 'active':''}`}>Solutions</div>
                                    <span className={`material-icons ${isSubMenuOpen ? 'rotate' : 'revert'}`}>arrow_drop_down</span>
                                  </div>
                                  <div onClick={() => handleMenuItemClick('/industries')} className={`linkBttn ${activePath.includes('/industries') ? 'active':''}`}>Industries</div>
                                  <div onClick={() => handleMenuItemClick('/references')} className={`linkBttn ${activePath.includes('/references') ? 'active':''}`}>References</div>
                                  <div onClick={() => handleMenuItemClick('/contact-us')} className={`linkBttn ${activePath.includes('/contact-us') ? 'active':''}`}>Contact Us</div>
                          </div>
                          <div className="contact-number-div">
                              <div className="customer-service-icon-container"><CustomerService/></div>
                              <div className="contact-number">
                                <div className="conatct-heading">Hotline</div>
                                <div className="phone-number">
                                  <a href={`tel:${settings?.phone ? settings.phone : '+656659'}`}>
                                    {settings?.phone ? settings.phone : '+65 6659 8168'}
                                  </a>
                                </div>
                              </div>
                              {/* <div className="yin-yang"></div> */}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <SolutionHeaderMenu isOpen={isSubMenuOpen} handleClose={() => setIsSubMenuOpen(false)} />
      </header>
    )}
      <div className="mobile-menu-popup-container">
        <div className={`menu-overlay ${isMenuOpen ? 'active': ''}`}>
          <div className="mobile-menu-items-div scroll-ref">
              <div onClick={() => handleMenuItemClick('/')} className={`mobileMenuButton ${activePath === '/' ? 'active':''}`}>Home</div>
              <div onClick={() => handleMenuItemClick('/about')} className={`mobileMenuButton ${activePath.includes('about') ? 'active':''}`}>About</div>
              <div className='solutions-sub-menu-container'>
                <div onClick={() => setIsSubMenuOpen(!isSubMenuOpen)} className={`mobileMenuButton ${activePath.includes('/solutions') ? 'active':''}`}>Solutions<span className={` material-icons ${isSubMenuOpen ? 'rotate' : 'revert'}`}>arrow_drop_down</span></div>
                {/* <div className={`mobile-sub-menu-container  ${isSubMenuOpen ? 'active': ''}`}>
                  <div className="mobile-submenu-item" onClick={() => setIsFireMenuOpen(!isFireMenuOpen)}><span><span></span>Fire Detection System</span><span className={` material-icons ${isFireMenuOpen ? 'rotate' : 'revert'}`}>arrow_drop_down</span></div>
                  <div className={`fire-detection-sub-menu ${isFireMenuOpen ? 'open' : 'close'}`}>
                  <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/fire-detection-system')}>Fire Detection System</div>
                  <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/fire-alarm-and-signalling')}>Fire Alarm and Signalling</div>
                  <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/flame-and-gas-detection')}>Flame and Gas Detection</div>
                  <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/control-panel')}>Fire & Gas Control Panel</div>
                  </div>
                  <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/high-pressure-water-mist-system')}>High-Pressure Water Mist System</div>
                  <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/halocarbon-fire-suppression-system')}>Halocarbon Suppression System</div>
                  <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/inert-gas-suppression-system')}>Inert Gas Suppression System</div>
                  <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/co2-suppression-system')}>CO2 Suppression System</div>
                  <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/foam-based-fire-suppression-system')}>Foam Suppression System</div>
                  <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/cctv-surveillance-systems')}>CCTV Surveillance systems</div>
                </div> */}
                <div className={`mobile-sub-menu-container  ${isSubMenuOpen ? 'active': ''}`}>
                  <div className="mobile-submenu-item fire-gas-ref" onClick={() => handleSubMenuItemClick('fire_gas')}><span> Fire & Gas Solutions</span><span className={` material-icons ${menuItemsStatus.fire_gas ? 'rotate' : 'revert'}`}>arrow_drop_down</span></div>
                  {/* sub menus */}
                  <div className={`solution-submenu-item-container ${menuItemsStatus.fire_gas? 'show':''}`}>
                    <div style={{ padding:'12px 16px'}}>Fire & Gas Detection</div>
                    <div style={{paddingLeft:'50px'}}>
                      <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/fire-detection-system')}>Fire Detection System</div>
                      <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/fire-alarm-and-signalling')}>Fire Alarm and Signalling</div>
                      <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/flame-and-gas-detection')}>Flame & Gas Detection</div>
                      <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/control-panel')}>Fire & Gas Control Panel</div>
                    </div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/high-pressure-water-mist-system')}>High-Pressure Water Mist System</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/halocarbon-fire-suppression-system')}>Halocarbon Suppression System</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/inert-gas-suppression-system')}>Inert Gas Suppression System</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/co2-suppression-system')}>CO2 Suppression System</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/foam-based-fire-suppression-system')}>Foam Suppression System</div>
                  </div>
                  <div className="mobile-submenu-item electrical-ref" onClick={() => handleSubMenuItemClick('author')}><span>Other Solutions</span><span className={` material-icons ${menuItemsStatus.author ? 'rotate' : 'revert'}`}>arrow_drop_down</span></div>
                  {/* submenu-items */}
                  <div className={`solution-submenu-item-container ${menuItemsStatus.author? 'show':''}`}>
                    <div style={{color:'rgba(41, 63, 142, 0.4)' , fontSize:'14px' , padding:'6px 8px'}}>Electrical & Automation</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/drill-monitoring-system')}>Drill Monitoring System</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/flare-boom-ignition-system')}>Flare Boom Ignition System</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/led-conversion-upgrades')}>LED Conversion Upgrades</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/emergency-shutdown-system')}>Emergency Shutdown System</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/bulk-weight-monitoring-system')}>Bulk Weight Monitoring System</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/cctv-surveillance-systems')}>CCTV Surveillance systems</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/power-management-system')}>Power Management System</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/jacking-control-system')}>Jacking Control System</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/vfd-control-system')}>VFD Control System</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/bilge-monitoring-control-system')}>Bilge Monitoring & Control System</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/talkback-and-pa-ga-systems')}>Talkback and PA/GA systems</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/rack-phase-differential-system')}>Rack Phase Differential System</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/watertight-door-monitoring-system')}>Watertight Door Monitoring System</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/vessel-management-system')}>Vessel Management System</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/scr-control-system')}>SCR Control System</div>

                    <div style={{color:'rgba(41, 63, 142, 0.4)' , fontSize:'14px' , padding:'6px 8px'}}>Mechanical Services</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/oil-gas-equipment')}>Oil & Gas Equipment</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/marine-equipment')}>Marine Equipment</div>
                    <div className="mobile-submenu-item" onClick={() => handleMenuItemClick('/solutions/other-industrial-equipment')}>Other Industrial Equipment</div>
                  </div>
                  {/* <div className="mobile-submenu-item mechanical-ref" onClick={() => handleSubMenuItemClick('mechanical')}><span>Mechanical Service</span><span className={` material-icons ${menuItemsStatus.mechanical ? 'rotate' : 'revert'}`}>arrow_drop_down</span></div>
                  <div className={`solution-submenu-item-container ${menuItemsStatus.mechanical? 'show':''}`}>

                  </div> */}
                </div>
              </div>
              <div onClick={() => handleMenuItemClick('/industries')} className={`mobileMenuButton ${activePath.includes('/industries') ? 'active':''}`}>Industries</div>
              <div onClick={() => handleMenuItemClick('/references')} className={`mobileMenuButton ${activePath.includes('/references') ? 'active':''}`}>References</div>
              <div onClick={() => handleMenuItemClick('/contact-us')} className={`mobileMenuButton ${activePath.includes('/contact-us') ? 'active':''}`}>Contact Us</div>
          </div>
          <div className="mobile-contact-info">
            <div className="contact-number-mobile-div">
                <div className="customer-service-icon-container-mobile"><CustomerService/></div>
                    <div className="contact-number-mobile">
                        <div className="conatct-heading">Hotline</div>
                        <div className="phone-number">                                  
                          <a href={`tel:${settings?.phone ? settings.phone : '+656659'}`}>
                              {settings?.phone ? settings.phone : '+65 6659 8168'}
                          </a>
                        </div>
                      </div>
                </div>
              <div>
                <span className='material-icons'>email</span>
                <span>
                  <a href={`mailto:${settings?.email ? settings.email : 'sales@hawkmarine.com.sg'}`}>
                      {settings?.email ? settings.email : 'sales@hawkmarine.com.sg'}
                  </a>
                </span>
              </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default Header;
