import React, { useEffect, useState } from 'react';
import carouselImg1 from '../../../assets/image/carouselImage2.webp';
import carouselImg2 from '../../../assets/image/carauselImg1.webp';
import carouselImg3 from '../../../assets/image/carouselImg3.webp';
import carouselImg4 from '../../../assets/image/carouselImg4.webp';

import './carousel.css';
import { ArrowDown, CarouselSvg } from '../../../assets/icons/iconIndex';
import { useNavigate } from 'react-router-dom';
import { getCarouselData } from '../../../API/services';
import config from '../../../API/configaration';

function Carousel() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(true);
  const [timer, setTimer] = useState(5000);
  const [intervalId, setIntervalId] = useState(null);
  const navigate = useNavigate();
  const triggerShake = () => {
    const svgElement = document.querySelector('.scroll-down svg');
    if (svgElement) {
      svgElement.style.animation = 'subtle-shake 1s ease-in-out';
      setTimeout(() => {
        svgElement.style.animation = '';
      }, 1000);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(triggerShake, 5000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(()=>{
    getCarouselData().then((res)=>{
      const updatedData = res.data.map((item) => ({
        ...item,
        image: item.image.startsWith('http') 
          ? item.image 
          : `${config.apiBaseUrl}${item.image}`,
      }));
      setCarouselData(updatedData)
    }).catch((error)=>{
      
    })
  },[])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsDescriptionVisible(false); // Hide description before changing
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
        setIsDescriptionVisible(true); // Show description after changing
      }, 300); // Duration of the fade-out
    }, timer);
    setIntervalId(intervalId);
    return () => clearInterval(intervalId);
  }, [timer]);

  const handleDotClick = (index) => {
    setIsDescriptionVisible(false); // Hide description before changing
    setTimeout(() => {
      setCurrentIndex(index);
      setIsDescriptionVisible(true); 
    }, 300);
    setTimer(30000);
    if (intervalId) {
      clearInterval(intervalId);
    }

    // Restart interval with new timer
    const id = setInterval(() => {
      setIsDescriptionVisible(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
        setIsDescriptionVisible(true); // Show description after changing
      }, 300); // Duration of the fade-out
    }, 30000); // Set interval to 30 seconds
    setIntervalId(id);
  };

  const [carouselData,setCarouselData] = useState([
    {
      image: carouselImg2,
      title: 'Fire and Gas Integrated Solutions',
      description: 'Enhance operational safety and efficiency with premium Fire & Gas integrated solutions, designed to safeguard infrastructure, assets, and personnel while minimizing lifecycle costs and risks.',
    },
    {
      image: carouselImg1,
      title: 'fire & gas solutions for marine',
      description:
        'When you are out at sea, you need to be self-sufficient <br> Keeping your crew and ship safe is priority number one.',
    },
    {
      image: carouselImg3,
      title: 'data center',
      description:
        'Need a fire fighting system that allows you to keep your data centre running while suppressing a fire?',
    },
    {
      image: carouselImg4,
      title: 'power generation',
      description:
        'The impact of fires on power generation facilities can be devastating to your people, your business and your customers.',
    },
  ]);

  const handleNavigation = (index) => {
    const item = carouselData[index];
    let slug = 'lng-process-plant'
    if(item.title.includes('data center')){
      slug = 'data-center'
    }else if(item.title.includes('marine')){
      slug = 'marine'
    }else if(item.title.includes('power generation')){
      slug = 'power-generation'
    }
    navigate(`/industries/${slug}`)
  }

  return (
    <div
      className='carousel-container'
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${carouselData[currentIndex]?.image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className='carousel-indicators'>
        {carouselData?.map((_, index) => (
          <div
            key={index}
            className={`dot ${currentIndex === index ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          ></div>
        ))}
      </div>
      <div className='container'>
        <h1 style={{ color: 'white' }} className='large-heading words-letter-capital'>
          {carouselData[currentIndex].title}
        </h1>
        <div className={`descriptions ${isDescriptionVisible ? 'fade-in' : 'fade-out'}`}>
          {carouselData[currentIndex]?.description.split('<br>').map((text, index) => (
            <p key={index} style={{ color: 'white', fontWeight: '400' }} className='medium-text'>
              {text}
            </p>
          ))}
        </div>
        <div className='button-group'>
          {/* <div className='card-button-white' onClick={()=> handleNavigation(currentIndex)}>Learn More</div> */}
          <div className='card-button-white' onClick={()=> navigate('/contact-us')}>Contact Us</div>
        </div>
      </div>
      <div className='scroll-down'>
        <a href='#who-we-are-section'>
          <span>Scroll Down</span>
          <ArrowDown />
        </a>
      </div>
      <CarouselSvg id='carousal-svg' />
    </div>
  );
}

export default Carousel;
