import React, { useEffect, useState } from 'react'
import '../../assets/css/productAndSolutions.css';
import mainImage from '../../assets/image/solutionHeadImage.webp'
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getPageHeaderWithName, getSolutionList } from '../../API/services';
import config from '../../API/configaration';
import 'quill/dist/quill.snow.css';
import waterMistSystemPdf from '../../assets/pdfs/Water_mist_technology.pdf';
import inertGasSuppressionPdf from '../../assets/pdfs/innert-gas-suppression.pdf';
import halocarbonPdf from '../../assets/pdfs/halocarbonPdf.pdf';
import co2Pdf from '../../assets/pdfs/co2Pdf.pdf';
import ansulFoamBasedPdf from '../../assets/pdfs/formPdf.pdf';
import scumFoamBasedPdf from '../../assets/pdfs/scumFoam.pdf';
import hs81brochurePdf from '../../assets/pdfs/HS-81_brochure.pdf';
import nfs23030brochurePdf from '../../assets/pdfs/NFS2-3030_pdf.pdf';
import sapphireBroucher from '../../assets/pdfs/sapphire.pdf';
import sapphire720Brouche from '../../assets/pdfs/sapphireplus70.pdf';
import mechanicalHeadImage from '../../assets/image/solutionMechanical.webp'
import electricalHeadImage from '../../assets/image/solutionElecetrical.webp'

function ProductAndSolutions() {
  const location = useLocation();
  const [headerImage,setHeaderImage] = useState(mainImage)
  const [headerData,setHeaderData] = useState({
    title: "Solutions",
    image: mainImage
  })
  const [contentData,setContentData] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0);
  const [updatedContent, setUpdatedContent] = useState('');
  // const [isBroucher,setIsBrochure] = useState(false);
  // const [broucherLink,setBroucherLink] = useState('');
  // const scrollToSection = (id) => {
  //   const element = document.getElementById(id);
  //   if (element) {
  //     const offsetTop = window.pageYOffset + element.getBoundingClientRect().top - 110;
  //     window.scrollTo({ top: offsetTop, behavior: 'smooth' });
  //   }
  // };
  // const cardClick = (path) => {
  //   if(path){
  //    const index = contentData.findIndex(item => item.path === path);  
  //     setCurrentIndex(index);
  //       setTimeout(() => scrollToSection('redirect-section'), 100);
  //   }else{
  //     setCurrentIndex(0)
  //   }
  // }
  const findItemBySlug = (data) => {
    if (data.length > 0) {
      const slug = location.pathname.split('/').pop();
      const index = data.findIndex((item) => item.path === slug);
      if (index !== -1) {
        setCurrentIndex(index);
        // setTimeout(() => scrollToSection('redirect-section'), 1000);
      }
    }
  };
  const pdfMapping = {
    'Water_mist_technology': waterMistSystemPdf,
    'inert-gas-suppression': inertGasSuppressionPdf,
    'FM-200-Broucher': halocarbonPdf,
    'co2-Brochure': co2Pdf,
    'Ansul-form-brochure': ansulFoamBasedPdf,
    'Scum-form-brochure': scumFoamBasedPdf,
    'HS-81-brochure': hs81brochurePdf,
    'NFS2-3030-brochure': nfs23030brochurePdf,
    'sapphire-brochure': sapphireBroucher,
    'sapphireplus70-brochure': sapphire720Brouche
  };
  const fetchData = async () => {
    const response = await fetch('/assets/json/solution.json');
    const datas = await response.json();
    setContentData(datas.solution[0])
  };
  useEffect(()=>{
    fetchData()
    getPageHeaderWithName('solutions').then((res)=>{
      const  data = res.data[0];
      data.image = config.apiBaseUrl + data.image
      setHeaderData(data)
    }).catch((error)=>{

    })
    getSolutionList().then((res)=>{
      const responce = res.data.map(item => ({
        ...item,
        image: config.apiBaseUrl + item.image,
      }));
      setContentData(responce)
      findItemBySlug(responce)
    }).catch((error)=>{
      
    })
  },[])

  useEffect(() => {
    findItemBySlug(contentData);
  }, [location.pathname, contentData]);

  useEffect(()=>{
    setHeadImage()
  },[currentIndex,contentData])

  const setHeadImage = () =>{
    if(contentData[currentIndex]?.product_type === 'mechanical'){
      setHeaderImage(mechanicalHeadImage)
    }else if(contentData[currentIndex]?.product_type === 'electrical'){
      setHeaderImage(electricalHeadImage)
    }else{
      setHeaderImage(mainImage)
    }
  }
  // useEffect(() => {
  //   const path = contentData[currentIndex]?.path
  //   if(path){
  //     if(path === 'high-pressure-water-mist-system'){
  //       setIsBrochure(true)
  //       setBroucherLink(waterMistSystemPdf)
  //     }else if(path === 'inert-gas-suppression-system'){
  //       setIsBrochure(true);
  //       setBroucherLink(inertGasSuppressionPdf);
  //     }else if(path === 'halocarbon-fire-suppression-system'){
  //       setIsBrochure(true)
  //       setBroucherLink(halocarbonPdf)
  //     }else if(path === 'foam-based-fire-suppression-system'){
  //       setIsBrochure(true)
  //       setBroucherLink(foamBasedPdf)
  //     }else if(path === 'co2-suppression-system'){
  //       setIsBrochure(true)
  //       setBroucherLink(co2Pdf)
  //     }
  //     else{
  //       setIsBrochure(false)
  //     }
  //   }
  // },[currentIndex,contentData])

  // function modifyHtml(html) {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(html, 'text/html');
  
  //   // Find all <a> tags
  //   const links = doc.querySelectorAll('a');
  
  //   links.forEach((link) => {
  //     const href = link.getAttribute('href');
  //     const buttonName = link.textContent || '';
  
  //     // Create the button element
  //     const button = doc.createElement('button');
  //     button.className = 'download-button';
  
  //     // Create the icon span
  //     const iconSpan = doc.createElement('span');
  //     iconSpan.className = 'material-icons';
  //     iconSpan.textContent = 'download';
  
  //     // Append the span and text to the button
  //     button.appendChild(iconSpan);
  //     button.appendChild(document.createTextNode(buttonName));
  
  //     // Add required attributes to the <a> tag
  //     link.className = 'download-button-container';
  //     link.setAttribute('download', true);
  
  //     // Append the button to the <a> tag
  //     link.innerHTML = ''; // Clear the <a>'s content
  //     link.appendChild(button);
  //   });
  
  //   return doc.body.innerHTML;
  // }
  function modifyHtml(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Find all <a> tags
    const links = doc.querySelectorAll('a');

    links.forEach((link) => {
      const href = link.getAttribute('href');
      const pdfKey = href.split('/').pop(); // Extract the file name from the href

      if (pdfMapping[pdfKey]) {
        // Replace the href with the correct PDF link
        link.setAttribute('href', pdfMapping[pdfKey]);
        link.setAttribute('download', pdfKey); 

        // Style the <a> tag with a button
        const buttonName = link.textContent || '';
        link.className = 'download-button-container';

        const button = document.createElement('button');
        button.className = 'download-button';

        const iconSpan = document.createElement('span');
        iconSpan.className = 'material-icons';
        iconSpan.textContent = 'download';

        button.appendChild(iconSpan);
        button.appendChild(document.createTextNode(buttonName));

        link.innerHTML = ''; // Clear existing content
        link.appendChild(button); // Add the button to the <a> tag
      }
    });

    return doc.body.innerHTML;
  }
  
  
  
  useEffect(() => {
    if (contentData[currentIndex]?.description) {
      const transformedHtml = modifyHtml(contentData[currentIndex].description);
      setUpdatedContent(transformedHtml);
    } else {
      setUpdatedContent('');
    }
  }, [currentIndex, contentData]);
  return (
    <>
      <Helmet>
        <title>Solutions</title>
        <meta name='description' content="Hawk Marine's Fire & Gas Detection Systems features advanced sensors that monitor combustible and toxic gases and detect fire, heat, and smoke to secure industrial facilities" />
        <meta name='keywords' content="hawk marine,hawkmarine,Fire Detection System,Fire Alarm and Signalling,Flame and Gas Detection,Fire & Gas Control Panel,fire and saftey,cctv,fire Suppression system,Fire & Gas Detection System,High-Pressure Water Mist System,Halocarbon Clean Agent Fire Suppression System,CCTV System,Foam-based Fire Suppression System,Inert Gas Suppression System"/>
      </Helmet>
      <div>
          <div className="product-solution-first-div scroll-ref" style={{backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),url(${headerImage})`}}>

            <h1 style={{color:'white'}} className="large-heading">{headerData.title}</h1>
            {/* <h1 style={{color:'white'}} className="large-heading">{contentData[currentIndex]?.title}</h1> */}
          </div>
          {/* <CardGroup onCardClick={cardClick}/> */}
          <div className="product-and-solutions-content" id='redirect-section'>
            <div className="container">
              <h1 className="large-heading" style={{marginTop:'60px'}}>{contentData[currentIndex]?.title}</h1>
              <div className="solution-content">
                <div className="" dangerouslySetInnerHTML={{ __html: updatedContent }} />
              </div>
              {/* {contentData[currentIndex]?.description.split('<br>').map((description,index) => (
                <p className="small-text paragraph" key={index}>{description}</p>
              ))}
              {contentData[currentIndex]?.subSection && (
                contentData[currentIndex].subSection.map((item,i) => (
                  <div className="product-and-solution-sub-content" key={i}>
                    <h2 className="content-heading">{item.heading}</h2>
                    {item.description && item.description.split('<br>').map((des , j) => (
                      <p style={{marginTop:'0px',marginBottom:'0px'}} className="small-text paragraph" key={j}>{des}</p>
                    ))}
                    {item.items && item.items.length > 0 && (
                      <div className="products-section">
                        {item.items.map((product,k)=>(
                          <div className='product-item' key={k}>
                            <h3 className="sub-heading">{product.title}</h3>
                            <div className="product-image-container"><img src={product.image} alt="" /></div>
                          </div>
                        ))}
                      </div>
                    )}
                    {item.list && item.list.length > 0 && (
                        item.list.map((product,k)=>(
                          <div key={k}>
                            <h3 style={{textAlign:'left'}} className="sub-heading">{product.heading}</h3>
                            {product.description && product.description.split('<br>').map((des,j)=>(
                            <p style={{marginTop:'0px'}} className="small-text paragraph" key={j}>{des}</p>
                            ))}
                          </div>
                        ))
                    )}
                    {item.points && item.points.length > 0 &&(
                      <ul className=''>
                        {item.points.split('<br>').map((point,k) => (
                          <li key={k} style={{marginTop:'10px'}}>
                              <p className='point-header'>● {point.split(':')[0]}{point.includes(':') ? ':' : ''}</p>{point.includes(':') && <p className='small-text'>{point.split(':')[1]}</p>}
                          </li>
                        ))}
                      </ul>
                    )}
                    {contentData[currentIndex]?.img &&(
                      <div className='image-container'><img src={data.img} alt="" /></div>
                    )}
                  </div>
                ))
              )} */}
              {/* <div className="product-and-solution-sub-content">
                <div className="content-heading">The power behind SEM-SAFE®</div>
                {paragraph.split('<br>').map((item, index) => (
                <p className='small-text' key={index}>{item}</p>
              ))}
  
              </div> */}
                  {/* {isBroucher && (
                  <a href={broucherLink} download className='download-button-container'><button className="download-button"><Download/><span>Download Brochure.</span></button></a>
                  )}
                  {contentData[currentIndex]?.path === 'control-panel' && (
                   <div className="download-button-group">
                      <a href={hs81brochurePdf} download className='download-button-container'><button className="download-button"><Download/><span>HS-81 Brochure.</span></button></a>
                      <a href={nfs23030brochurePdf} download className='download-button-container'><button className="download-button"><Download/><span>NFS2-3030 Brochure.</span></button></a>
                   </div>
                  )} */}
            </div>
          </div>
      </div>
    </>
  )
}

export default ProductAndSolutions