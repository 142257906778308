// import React, { useEffect, useState } from 'react'
// import '../../assets/css/reference.css';
// import headerImage from '../../assets/image/energy-generation-1.jpg'
// import logo from '../../assets/image/company/company1.png';
// import image1 from '../../assets/image/carouselImg2.webp';
// import image2 from '../../assets/image/carouselImg3.webp';
// import { getReferenceList } from '../../API/services';

// function References() {
//   const [page, setPage] = useState(1)
//   const [totalPages, setTotalPages] = useState(1);
//   const [isLoading, setIsLoading] = useState(true);
//   const [pageData, setPageData] = useState({
//     image: headerImage,
//     title: 'References',
//     description: '2018,2019,2020,2021,2022,2023'
//   })
//   const [data, setData] = useState([
//     {
//       id: 1,
//       year: 2018,
//       title: 'BAB Integrated Facilities Project (BIFP)',
//       country: 'UAE',
//       customer_name:'Verthae',
//       end_user: 'Rihadh transport',
//       project_value: '$130K',
//       month_and_year: '01/2018',
//       vertical: 'O & G',
//       project_destination:'India',
//       region:'Asia',
//       supply:'30 days maintenence'
//     }
//   ])

//   const getList = (pageNo) => {
//     setIsLoading(true)
//     getReferenceList(pageNo).then((ress) => {
//       const newArray = data
//       data.push(ress.data)
//       if (page === 1) {
//         setData(ress.data)
//       } else {
//         setData([...data, ress.data])
//       }
//       setIsLoading(false)
//     }).catch((error) => {

//     })
//   }

//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll);
//     getList(1)
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };

//   }, [])



//   const handleScroll = () => {
//     const scrollPosition = window.scrollY + window.innerHeight;
//     const bottomPosition = document.documentElement.scrollHeight;

//     if (scrollPosition >= bottomPosition - 500 && !isLoading) {
//       if (page <= totalPages) {
//         setPage(page + 1);
//         getList(page + 1);
//       }
//     }
//   };

//   const years = pageData.description.split(',');
//   return (
//     <div>
//       <div className="reference-first-section" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${pageData.image})` }}>
//         <h1 className="large-heading">{pageData?.title}</h1>
//       </div>
//       <div className="refrence-year-section">
//         <div className="refrence-year-title">References</div>
//         <div className="refrence-years-div">
//           {years.map((year, index) => (
//             <React.Fragment key={year}>
//               <div className="refrence-year-item">{year}</div>
//               {index < years.length - 1 && <span className="dash">-</span>}
//             </React.Fragment>
//           ))}

//         </div>
//       </div>
//       <div className="refrence-card-container">
//         {data?.map((item, index) => (
//           <div className="refrence-card" key={index}>
//             <div className="refrence-card-heading-div">
//               <div className="refrence-year-heading">{item?.year} REFERENCE</div>
//               <span>|</span>
//               <div className="refrence-project-name">{item?.title}</div>
//             </div>
//             <div className="card-content-section-container">
//               <div className="refrence-card-section-1">
//               <div className="section-heading">Project Data</div>
//                 {item.customer &&
//                   <div className="section-item-container">
//                     <span>Customer:</span>
//                     <span className='item'>{item.customer}</span>
//                   </div>
//                 }
//                 {item.country &&
//                   <div className="section-item-container">
//                     <span>Country:</span>
//                     <span className='item'>{item.country}</span>
//                   </div>
//                 }
// {/* 
//                 {item.use_case &&
//                   <div className="section-item-container">
//                     <span>Use Case:</span>
//                     <span className='item'>{item.use_case}</span>
//                   </div>
//                 }
//                 {item.direct_customer &&
//                   <div className="section-item-container">
//                     <span>Direct Customer:</span>
//                     <span className='item'>{item.direct_customer}</span>
//                   </div>
//                 } */}

//               </div>
//               <div className="refrence-card-section-2">
//               {item.end_user &&
//                   <div className="section-item-container">
//                     <span>End User:</span>
//                     <span className='item'>{item.end_user}</span>
//                   </div>
//                 }
//                 {item.vertical &&
//                     <div className="section-item-container">
//                       <span>Vertical:</span>
//                       <span className='item'>{item.vertical}</span>
//                     </div>
//                 }
//                 {item.project_value &&
//                   <div className="section-item-container">
//                     <span>Project Value:</span>
//                     <span className='item'>{item.project_value}</span>
//                   </div>
//                 }

//               </div>
//               <div className="refrence-card-section-1">
//               {item.month_and_year &&
//                   <div className="section-item-container">
//                     <span>Month/year:</span>
//                     <span className='item'>{item.month_and_year}</span>
//                   </div>
//                 }
//                 {item.project_destination &&
//                     <div className="section-item-container">
//                       <span>Project Destination:</span>
//                       <span className='item'>{item.project_destination},{item.region}</span>
//                     </div>
//                   }             
//                   {item.supply &&
//                     <div className="section-item-container">
//                       <span>supply:</span>
//                       <span className='item'>{item.supply}</span>
//                     </div>
//                   }
//                 {/* <p>{item.supplay}</p> */}
//               </div>
//             </div>
//           </div>
//         ))}

//       </div>
//       {isLoading &&
//         <div className="shimmer-card">
//           {/* Shimmer Heading */}
//           <div className="shimmer-heading"></div>
//           .card-content-section
//           {/* Shimmer Content Section (e.g., Project Details) */}
//           <div className="shimmer-content">
//             <div className="shimmer-content-item">
//               <div className="shimmer-item-line"></div> {/* Country */}
//               <div className="shimmer-item-line"></div> {/* End User */}
//               <div className="shimmer-item-line"></div> {/* Project Value */}
//               <div className="shimmer-item-line"></div> {/* Month/Year */}
//               <div className="shimmer-item-line"></div> {/* Segment */}
//               <div className="shimmer-item-line"></div> {/* Use Case */}
//               <div className="shimmer-item-line"></div> {/* Direct Customer */}
//               <div className="shimmer-item-line"></div> {/* KAM */}
//             </div>
//             {/* Shimmer Image Section */}
//             <div className="shimmer-image"></div>
//           </div>
//         </div>
//       }
//     </div>
//   )
// }

// export default References



import React, { useEffect, useRef, useState } from 'react'
import '../../assets/css/referencePage.css';
import headerImage from '../../assets/image/energy-generation-1.jpg'
import { getPageHeaderWithName, getReferenceList } from '../../API/services';
import axios from 'axios';
import config from '../../API/configaration';
import { useGeneralContext } from '../../GeneralContext';

function References() {
  const [page, setPage] = useState(1)
  let link = ''
  const [totalPages, setTotalPages] = useState(1);
  const [selectedYear,setSelectedYear] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const {setScrollToTopVisibility} = useGeneralContext()
  const [pageData, setPageData] = useState({
    image: headerImage,
    title: 'References',
    description: '2020,2021,2022,2023,2024'
  })
  const [data, setData] = useState([ ])
  const pageRef = useRef(page);
  const totalPagesRef = useRef(totalPages);

  const getList = (next,year) => {
    if (isLoading) return;
    setIsLoading(true)
    if(!next){
      getReferenceList(year).then((res)=>{
        setData(res?.data?.results);
        setPage(res?.data?.page);
        setTotalPages(res?.data?.total_pages)
        setIsLoading(false);
        link = res?.data?.links?.next
        setTimeout(() => {
          // setNextLink(res?.data?.links?.next)      
        }, 0);
        
      }).catch((error)=>{
        // setTimeout(() => {
        //   getList(next,year)
        // }, 20000);
      })
    }else{
        setIsLoading(true)
        axios.get(next).then((res)=>{
          const newData = res?.data?.results || [];
          const filteredData = newData.filter((item) => 
            !data.some(existingItem => existingItem.id === item.id)
          );
          setData((prev) => [...prev, ...filteredData]);
          link = res?.data?.links?.next
          setPage(res?.data?.page);
          setIsLoading(false)
          setTotalPages(res?.data?.total_pages)
        }).catch((error)=>{
          // setTimeout(() => {
          //   getList(next,year)
          // }, 20000);
        })
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, [])

  useEffect(()=>{
    setSelectedYear('')
    getList(false,'')
    getPageHeaderWithName('reference').then((res)=>{
      setPageData(res.data[0])
    }).catch((error)=>{
      // console.log(error)
    })
  },[])

  useEffect(()=>{
    pageRef.current = page;
    totalPagesRef.current = totalPages;
  },[page,totalPages])

  const handleYearFilter = (year) => {
    if(selectedYear === year){
      setSelectedYear('')
      getList(false,'')
    }else{
      setSelectedYear(year)
      getList(false,year)
    }
  }

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const bottomPosition = document.documentElement.scrollHeight;

    if (scrollPosition >= bottomPosition - 600) {
      if(pageRef.current < totalPagesRef.current){
        if(!isLoading){
          getList(link,selectedYear)
        }
      }
    }
  };
  function formatNumber(value) {
    if (value >= 1000000) {
      return `${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}k`;
    }
    return value; // For values below 1000
  }
  useEffect(() => {
    const targetElements = document.querySelectorAll('.page-top-ref');
    const observer = new IntersectionObserver(
      (entries) => {
        const anyIntersecting = entries.some((entry) => entry.isIntersecting);
        setScrollToTopVisibility(!anyIntersecting);
      },
      { root: null, threshold: 0.1 }
    );
    targetElements.forEach((element) => observer.observe(element));

    return () => {
      targetElements.forEach((element) => observer.unobserve(element));
    };
  }, [setScrollToTopVisibility]);

  // const years = pageData?.description?.split(',');
  
  return (
    <div>
      <div className="reference-first-section scroll-ref" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${config.apiBaseUrl + pageData.image})` }}>
        <h1 className="large-heading">{pageData?.title}</h1>
      </div>
      {/* <div className="refrence-year-section page-top-ref">
        <div className="refrence-year-title">References</div>
        <div className="year-filter-container">
      {years.map((year, index) => (
        <React.Fragment key={year}>
          <button
            className={`year-button ${selectedYear === year ? 'selected' : ''}`}
            onClick={() => handleYearFilter(year)}
          >
            {year}
          </button>
          {index < years.length - 1 && <span className="dash">-</span>}
        </React.Fragment>
      ))}
    </div>
        {/* <div className="refrence-years-div">
          {years?.map((year, index) => (
            <React.Fragment key={year}>
              <div className="refrence-year-item" onClick={()=>handleYearFilter(year)}>{year}</div>
              {index < years.length - 1 && <span className="dash">-</span>}
            </React.Fragment>
          ))}

        </div> 
      </div> */}
      <div className="reference-note">
        Note: {pageData?.description}
        {/* <p className="note-text">{pageData?.description}</p> */}
      </div>
      <div className="refrence-card-container">
        {data?.map((item, index) => (
          <div className="refrence-card" key={index}>
            <div className="refrence-card-heading-div">
              <div className="refrence-year-heading">{item?.year} REFERENCE</div>
              <span>|</span>
              <div className="refrence-project-name">{item?.project_name}</div>
            </div>
            <div className="card-content-first-section">
              {/* <div className="first-content-item-container">
                <span className='material-icons'>account_circle</span>
                <span className='item-text'>Customer</span>
                <span className='content-text'>{item.customer_name}</span>
              </div> */}
              {item.industry &&
                <div className="first-content-item-container">
                  <span className='material-icons'>category</span>
                  <span className='item-text'>Industry</span>
                  <span className='content-text'>{item.industry}</span>
                </div>
              }
              {item.protected_area &&
                <div className="first-content-item-container">
                  <span className='material-icons'>shield</span>
                  <span className='item-text'>Protected Area</span>
                  <span className='content-text'>{item.protected_area}</span>
                </div>
              }
              {item.country &&
                <div className="first-content-item-container">
                  <span className='material-icons'>language</span>
                  <span className='item-text'>Country</span>
                  <span className='content-text'>{item.country}</span>
                </div>
              }
              {item.end_client &&
                <div className="first-content-item-container">
                  <span className='material-icons'>groups</span>
                  <span className='item-text'>End User</span>
                  <span className='content-text'>{item.end_client}</span>
                </div>
              }
              {/* {item?.project_value &&
                <div className="first-content-item-container">
                  <span className='material-icons'>diamond</span>
                  <span className='item-text'>Project Value</span>
                  <span style={{fontWeight:'900'}} className='content-text'>${formatNumber(item.project_value)}</span>
                </div>
              } */}
              {item.year &&
                <div className="first-content-item-container">
                  <span className='material-icons'>calendar_month</span>
                  <span className='item-text'>Year</span>
                  <span className='content-text'>{item.year}</span>
                </div>
              }

              {/* {item.project_destination &&
                <div className="first-content-item-container">
                  <span className='material-icons'>location_on</span>
                  <span className='item-text'>Project Destination</span>
                  <span className='content-text'>{item?.project_destination},{item.region}</span>
                </div>
              } */}
            </div>
            {item.description && (
              <div className="supply-section">
                <h4 className="supply-title">Description</h4>
                <p className="supply-text">{item.description}</p>
              </div>
            )}
          </div>
        ))}

      </div>
      {isLoading &&
        <div className="refrence-card">
          <div className="refrence-card-heading-div">
            <div className="refrence-year-heading"><div className='shimmer-div large' style={{width:'150px'}}></div> REFERENCE</div>
            <span>|</span>
            <div className="refrence-project-name"><div className='shimmer-div large' style={{width:'250px'}}></div></div>
          </div>
          <div className="card-content-first-section">
              <div className="first-content-item-container">
                  <span className='material-icons'>category</span>
                  <span className='item-text'>Industry</span>
                  <span className='content-text'><div className='shimmer-div content-text'></div></span>
              </div>
              <div className="first-content-item-container">
                <span className='material-icons'>shield</span>
                <span className='item-text'>Protected Area</span>
                <span className='content-text'><div className='shimmer-div content-text'></div></span>
              </div>
              <div className="first-content-item-container">
                <span className='material-icons'>language</span>
                <span className='item-text'>Country</span>
                <span className='content-text'><div className='shimmer-div content-text'></div></span>
              </div>
              <div className="first-content-item-container">
                <span className='material-icons'>groups</span>
                <span className='item-text'>End User</span>
                <span className='content-text'><div className='shimmer-div content-text'></div></span>
              </div>
              {/* <div className="first-content-item-container">
                <span className='material-icons'>diamond</span>
                <span className='item-text'>Project Value</span>
                <span className='content-text'><div className='shimmer-div content-text'></div></span>
              </div> */}
              <div className="first-content-item-container">
                <span className='material-icons'>calendar_month</span>
                <span className='item-text'>Year</span>
                <span className='content-text'><div className='shimmer-div content-text'></div></span>
              </div>

              {/* <div className="first-content-item-container">
                <span className='material-icons'>location_on</span>
                <span className='item-text'>Project Destination</span>
                <span className='content-text'><div className='shimmer-div content-text'></div></span>
              </div> */}
          </div>
            <div className="supply-section">
              <h4 className="supply-title">Description</h4>
              <p className="supply-text"><div className='shimmer-div large'></div></p>
            </div>
        </div>
      }
    </div>
  )
}

export default References