import React from 'react';
import './menuButton.css';

const MenuButton = ({ onClick, menuVisible }) => {
  return (
    <div className="menu_button">
      <input
        type="checkbox"
        id="menu_checkbox"
        checked={menuVisible}
        onChange={onClick}
      />
      <label htmlFor="menu_checkbox" id="menu_label">
        <div className="menu_text_bar"></div>
      </label>
    </div>
  );
};

export default MenuButton;
