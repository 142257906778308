import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Home from './modules/Home';
import Header from './modules/Header';
import Footer from './modules/Footer';
import { useEffect, useState } from 'react';
import ProductAndSolutions from './modules/Pages/ProductAndSolutions';
import Industries from './modules/Pages/Industries';
import About from './modules/Pages/About';// Component for dynamic product detail pages
import ContactUs from './modules/Pages/ContactUs';
import References from './modules/Pages/References';
import ScrollUpButton from './modules/Common/scroll-up-button/ScrollUpButton';
import ToastProvider from './modules/Common/utils/ToastProvider';

function App() {
  const location = useLocation();
  const [transitionClass, setTransitionClass] = useState('page'); // Track page transition class

  useEffect(() => {
    setTransitionClass('page');
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setTransitionClass('page page-active');
    }, 300);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  return (
    <div className="App">
      <Header />
      <ToastProvider/>
      <ScrollUpButton />
      {/* Apply transition class to the page container */}
      <div className={transitionClass}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/solutions" element={<ProductAndSolutions />} />
          <Route path="/industries" element={<Industries />} />
          <Route path='/contact-us' element={<ContactUs/>}/>
          <Route path='/references' element={<References/>}/>
          {/* Dynamic route to handle sub-paths */}
          <Route path="/solutions/:slug" element={<ProductAndSolutions />} />
          <Route path="/industries/:slug" element={<Industries />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
