import React, { useEffect, useState } from 'react'
import './companyList.css';
import { getCompanyList } from '../../../API/services';
import config from '../../../API/configaration';
function CompanyList() {
    
    const [companyList,setComapnyList] = useState();
    useEffect(()=>{
        getCompanyList().then((res)=>{
            setComapnyList(res.data);
        }).catch((error)=>{
        })
    },[]) 
  return (
    <>
        <div className="trusted-by-div">
            <h1 className='heading-div'><div className='large-heading'>Partnered With Industry-Leading Brands</div></h1>
            <div className="companys-list">
                <div id='contact-us' className="company-scroll">
                    {companyList?.map((item, index) => (
                        <img onClick={() =>window.open(item.website, "_blank")} src={config.apiBaseUrl + item.logo} alt={item.alt} key={index} />
                    ))}
                </div>
            </div>
        </div>
    </>
  )
}

export default CompanyList