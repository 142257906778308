import axios from 'axios';
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://hawk.ecogo.co.in/api/',
});
axiosInstance.interceptors.request.use(
  (config) => {
     if (config.url.includes('formData/')) {
      config.url = config.url.replace('formData/', '');
    } else {
      config.headers['Content-Type'] = 'application/json';
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axiosInstance;
