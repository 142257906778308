import React, { useEffect, useRef, useState } from 'react'
import CorporateVideo from '../Common/corporateVideo/CorporateVideo'
import '../../assets/css/about.css'
import headImage from '../../assets/image/aboutImage.webp'
// import aboutSectionTwo from '../../assets/image/aboutSectionTwo.svg'
import backGoundPng from '../../assets/image/Hawk-PPT-V02-18.png'
import ourMission from '../../assets/image/ourMissionImg.png'
import ourVision from '../../assets/image/ourVission.png'
import  ourValue1 from '../../assets/image/coreValueImg1.png'
import  ourValue2 from '../../assets/image/coreValueImg2.png'
import  ourValue3 from '../../assets/image/coreValueImg3.png'
import { ArcLeftBig, ArcLeftSmall, Download } from '../../assets/icons/iconIndex'
import { Helmet } from 'react-helmet'
import useOnScreen from '../Common/utils/useOnScreen'
import { getPageHeaderWithName } from '../../API/services'
import config from '../../API/configaration'
import hawkPresentation from '../../assets/pdfs/Hawk Presentation.pdf';

function About() {
    const sectionRef = useRef();
    const sectionRefTwo = useRef();
    const isVisible = useOnScreen(sectionRef);
    const isVisibleTwo = useOnScreen(sectionRefTwo);
    const [pageData,setPageData] = useState({
        image:headImage,
        title:"Company Overview",
        description:"Our core mission at Hawk Marine is to safeguard lives and assets by offering advanced fire and gas safety solutions. Our collaborative efforts drive the integration of solutions that exceed regulatory standards, ensuring superior safety and reliability. We provide continuous support and maintenance services; our team is dedicated to resolving issues and extending the operational life and reliability of your systems. Our extensive experience across oil & gas, marine and land sectors give us a deep understanding of the unique challenges in hazardous environments.<br>We offer a complete solution by safeguarding your infrastructure, plant assets, and personnel while maintaining peak operational uptime during potential emergencies. From oil & gas operations to land-based facilities, we go beyond being your safety provider. Hawk Marine is your trusted partner in maintaining smooth and secure operations.",
    });

    useEffect(()=>{
        getPageHeaderWithName('about').then((res)=>{
            const  data = res.data[0];
            data.image = config.apiBaseUrl + data.image
            setPageData(data)
        }).catch((error)=>{

        })
    },[])
  return (
    <>
      <Helmet>
        <title>About</title>
        <meta name='description' content="Our core mission at Hawk Marine is to safeguard lives and assets by offering advanced fire and gas safety solutions. Our collaborative efforts drive the integration of solutions that exceed regulatory standards, ensuring superior safety and reliability. We provide continuous support and maintenance services; our team is dedicated to resolving issues and extending the operational life and reliability of your systems. Our extensive experience across oil & gas, marine and land sectors give us a deep understanding of the unique challenges in hazardous environments." />
        <meta name='keywords' content="hawk marine,hawkmarine,Fire prevention,Fire safety,,fire and saftey,cctv,fire Suppression system,Fire & Gas Detection System,High-Pressure Water Mist System,Halocarbon Clean Agent Fire Suppression System,CCTV System,Foam-based Fire Suppression System,Inert Gas Suppression System"/>
      </Helmet>
    <div className="about-first-section scroll-ref" style={{backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${pageData.image})`}}>
        <h1 className="large-heading">{pageData?.title}</h1>        
    </div>
    <div className='corporate-vedio-section'>
        <div className='container about'>
            <div className='corporate-vedio-container'>
                {/* <CorporateVideo/> */}
            {pageData.description && pageData?.description.split('<br>').map((des,i)=>(
            <p className="small-text paragraph" key={i}>{des}</p>
            ))}
            {/* <div className="small-text">Whatever the nature of your business, the technicians at HAWK are on hand to ensure that your premises stay safe and in full compliance with all legal requirements.</div> */}
            {/* <div className="small-text">Recognized as a market leader in the field of fire protection, Hawk Marine has its operational headquarters in Singapore and subsidiary offices in Malaysia and India.</div> */}
            </div>
        </div>
    </div>
    <div ref={sectionRef} className={`about-second-section ${isVisible? 'aos-init aos-animate' : 'aos-init'}`}>
        <ArcLeftBig className='arcLeftBig'/><ArcLeftSmall className='arcLeftSmall'/>
        {/* <div className='about-second-image-div'><img src={backGoundPng} alt="" /></div> */}
        <div className='container'>
            <div className="about-second-content">
                <div className="about-second-content-section-1-container">
                    <div className="section-1-item">
                        <div className="section-1-item-image"><img src={ourMission} alt="" /></div>
                        <div className="section-1-item-content">
                            <h3 className="heading">Our Mission</h3>
                            <p className="small-text">To provide best-in-class fire detection and suppression solutions by offering tailored design, supply, integration, installation, and commissioning services. With a focus on innovation, quality, and compliance, we strive to ensure the safety of our clients' personnel and operations while delivering value-driven solutions worldwide.
                            </p>
                        </div>
                    </div>
                    <div className="section-1-item">
                        <div className="section-1-item-image"><img src={ourVision} alt="" /></div>
                        <div className="section-1-item-content">
                            <h3 className="heading">Our Vision</h3>
                            <p className="small-text">To be the trusted global leader in fire protection, exceeding client expectations through innovative solutions, exceptional service, and a steadfast commitment to safeguarding lives and assets worldwide.
                            </p>
                        </div>
                    </div>
                </div>
                <div ref={sectionRefTwo} className={`about-second-content-section-2-container ${isVisibleTwo ? 'aos-init aos-animate' : 'aos-init'}`}>
                    <h2 className="heading">Our Core Values</h2>
                    <div className="our-core-value-container">
                        <div className="our-core-value-item">
                            <div className="our-core-value-heading-div">
                                <div className='our-core-image-container'><img src={ourValue1} alt="" /></div>
                                <h3 className='heading'>Reliability & Trust</h3>
                            </div>
                            <div className="our-core-calue-content">
                                <p className="small-text">
                                We ensure our customers are legally compliant, and always give justified reasons for our recommendations in line with nationally recognised standards.
                                </p>
                            </div>
                        </div>
                        {/* 2 */}
                        <div className="our-core-value-item">
                            <div className="our-core-value-heading-div">
                                <div className='our-core-image-container'><img src={ourValue2} alt="" /></div>
                                <h3 className='heading'>Top Quality Service</h3>
                            </div>
                            <div className="our-core-calue-content">
                                <p className="small-text">We provide high quality services and ensure the safety of our customers’ lives, staff and property; they can be confident that they are in safe hands.</p>
                            </div>
                        </div>
                        {/* 3 */}
                        <div className="our-core-value-item">
                            <div className="our-core-value-heading-div">
                                <div className='our-core-image-container'><img src={ourValue3} alt="" /></div>
                                <h3 className='heading'>Partnership</h3>
                            </div>
                            <div className="our-core-calue-content">
                                <p className="small-text">We provide a single point-of-contact and are always prepared to listen to our clients. We offer tailor-made solutions where necessary.</p>
                            </div>
                        </div>
                    </div>
                    <div className='download-button-container'><a href={hawkPresentation} download><button className="download-button"><Download/><span>Corporate Presentation</span></button></a></div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default About