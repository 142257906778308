import React, { useEffect, useRef, useState } from 'react';
import CardGroup from './Common/cards/CardGroup';
import datacenter from '../assets/image/industries/dataCenter.webp';
import lngImage from '../assets/image/industries/LNG.webp'
import marineImage from '../assets/image/industries/marineImage.webp'
import offshoreImage from '../assets/image/industries/offshore.webp'
import powerGeneration from '../assets/image/industries/powerGeneration.webp';
import miningPlant from '../assets/image/industries/miningPlant.webp';
import { ArcLeftBig, ArcLeftSmall, ArcRightBig, ArcRightSmall, Arrow } from '../assets/icons/iconIndex';
import '../assets/css/Home.css';
import Carousel from './Common/carousel/Carousel';
import CorporateVideo from './Common/corporateVideo/CorporateVideo';
import { useNavigate } from 'react-router-dom';
import CompanyList from './Common/trustedCompanyList/CompanyList';
import ContactSection from './Common/contact-section/ContactSection';
import { Helmet } from 'react-helmet';
import useOnScreen from './Common/utils/useOnScreen';
import MajorIndustryItem from './Common/MajorIndustryItem';
import ServiceItem from './Common/ServiceItem';
import { getIndustriesData, getPageHeaderWithName } from '../API/services';
function Home() {
  const navigate = useNavigate()
  const [pageData,setPageData] = useState()
  const [bannerData,setBannerData] = useState()
  const [whoWeAreSection,setWhoWeAreSection] = useState({
    title: 'Who We Are',
    description: 'Our core mission at Hawk Marine is to safeguard lives and assets by offering advanced fire and gas safety solutions. Our collaborative efforts drive the integration of solutions that exceed regulatory standards, ensuring superior safety and reliability. We provide continuous support and maintenance services; our team is dedicated to resolving issues and extending the operational life and reliability of your systems. Our extensive experience across oil & gas, marine and land sectors give us a deep understanding of the unique challenges in hazardous environments.<br>We offer a complete solution by safeguarding your infrastructure, plant assets, and personnel while maintaining peak operational uptime during potential emergencies. From oil & gas operations to land-based facilities, we go beyond being your safety provider. Hawk Marine is your trusted partner in maintaining smooth and secure operations.',
  })
  const [solutionSection,setSolutionSection] = useState()
  const [howCanWeHelpSection,setHowCanWeHelpSection] = useState()
  const [majorIndustriesSection,setMajorIndustriesSection] = useState()
  const [coorporateVedio,setCoorporateVedio] = useState()
const [industriesData,setIndustriesData] = useState([
  {
    id:'data-center',
    image:datacenter,
    title:'Data Center',
    description:"Hawk Marine delivers advanced fire and gas solutions designed specifically for data centre safety.",
  },{
    id:'lng-process-plant',
    image:lngImage,
    title:'LNG Process Plant',
    description:"We provide advanced safety systems for LNG operations, prioritising excellent protection and reliability."
  },{
    id:'marine',
    image:marineImage,
    title:'Marine',
    description:"Hawk Marine presents innovative safety solutions for marine operations, ensuring trustworthy protection."
  },{
    id:'offshore-and-oil&gas',
    image:offshoreImage,
    title:'Offshore and Oil & Gas',
    description:"Hawk Marine specialises in expert safety solutions delivering vital protection in offshore and Oil & Gas sectors."
  },{
    id:'power-generation',
    image:powerGeneration,
    title:'Power Generation',
    description:"High-standard fire safety solutions and reliability in power generation with innovative solutions."
  },{
    id:'mining-process-plant',
    image:miningPlant,
    title:'Mining Process Plant',
    description:"Leading the way in mining safety with advanced technology solutions."
  }
])
useEffect(()=>{
  getPageHeaderWithName('home').then((res)=>{
    const data = res.data[0]
    setPageData(data)
    setBannerData(data.banners)
    const whoWeAre = data?.banners.find(x => x.id === 1)
    setWhoWeAreSection(whoWeAre)
    const  howCanWeHelp = data?.banners.find(x => x.id === 3)
    setHowCanWeHelpSection(howCanWeHelp)
    const solution = data?.banners.find(x => x.id === 2)
    setSolutionSection(solution)
    const industries = data?.banners.find(x => x.id === 4)
    setMajorIndustriesSection(industries)

  }).catch((error)=>{

  })
  getIndustriesData().then((res)=>{
    setIndustriesData(res.data)
  }).catch((error)=>{
    
  })
},[])
  const onCardClick = (path) => {
    if(path){
      navigate(`/solutions/${path}`)
    }else{
      navigate('/solutions')
    }
  }
  // animations
  const OurSolution = useRef();
  const solutionVisible = useOnScreen(OurSolution);

  return (
    <div className='scroll-ref'>
    <Helmet>
        {/* <title>Home - Hawk Marine</title> */}
        <title>Fire and Gas Detection Solutions Provider</title>
        <meta name="description" content="Hawk Marine delivers advanced fire and gas detection solutions tailored for marine safety, protecting vessels and crew with cutting-edge technology" />
        <meta name="keywords" content="hawk marine, HAWK, fire and gas solutions, marine safety, fire protection systems, marine technology, hawk marine pvt ltd" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://www.hawkmarine.com.sg/" />
        <link rel="icon" href="https://www.hawkmarine.com.sg/favicon.ico" type="image/x-icon" />
        <meta property="og:title" content="Home - Hawk Marine" />
        <meta property="og:description" content="Hawk Marine delivers advanced fire and gas solutions designed specifically." />
        <meta property="og:image" content="https://www.hawkmarine.com.sg/assets/image/preview.png" />
        <meta property="og:url" content="https://www.hawkmarine.com.sg/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Home - Hawk Marine" />
        <meta name="twitter:description" content="Hawk Marine delivers advanced fire and gas solutions designed specifically." />
        <meta name="twitter:image" content="https://www.hawkmarine.com.sg/assets/image/preview.png" />
    </Helmet>
    {/* <Helmet>
      <title>Home - Hawk Marine</title>
      <meta name="description" content="Hawk Marine delivers advanced fire and gas solutions designed specifically"/>
      <meta name="keywords" content='hawk marine,HAWK,hawk,hawk marine pvt ltd'/>
    </Helmet> */}
    <Carousel/>
    <div id='who-we-are-section' className="who-we-are-section">
      <ArcRightBig className='arc-right-big'/>
      <ArcRightSmall className='arc-right-small'/>
      <div className='container'>
        <div className='who-we-are-part-one'>
          <h1 style={{color:'white'}} className="large-heading">{whoWeAreSection?.title}</h1>
          {whoWeAreSection?.description && whoWeAreSection?.description.split('<br>').map((des,i)=>(
                <p style={{color:'white'}} className="small-text paragraph" key={i}>{des}</p>
          ))}
          <div  style={{color:'white'}} className="read-more-button" onClick={()=> navigate('/about')}>Read More <Arrow style={{fill:'white'}}/></div>
        </div>
        {/* <div className="who-we-are-part-two">
          <CorporateVideo videoSrc={config.apiBaseUrl + coorporateVedio}/>
        </div> */}
      </div>
    </div>
    <div ref={OurSolution} className={`our-solution-section ${solutionVisible ? 'aos-init aos-animate' : 'aos-init'}`}>
      <div className="container">
        <h1 className="large-heading">{solutionSection?.title}</h1>
        {solutionSection?.description && solutionSection?.description.split('<br>').map((des,i)=>(
          <p className='small-text' key={i}>{des}</p>
        ))}
      </div>
      <CardGroup onCardClick={onCardClick}/>
    </div>
    <section className="how-can-we-help-section pop-up">
      <div className="container">
        <h1 className="large-heading">{howCanWeHelpSection?.title}</h1>
        {howCanWeHelpSection?.description && howCanWeHelpSection?.description.split('<br>').map((des,i)=>(
          <p className='small-text' key={i}>{des}</p>
        ))}
        <div className='how-can-we-help-card-container'>
          {howCanWeHelpSection?.sections.length>0 && [...howCanWeHelpSection?.sections].sort((a,b)=> a.priority - b.priority).map((item,index)=>(
            <ServiceItem key={index} item={item} />
          ))}
       
        </div>
      </div>
    </section>
    {/* our majority industries */}
    <div className='major-industries-section'>
      <ArcLeftBig className='arcLeftBig'/>
      <ArcLeftSmall className='arcLeftSmall'/>
      <div className="container">
          <h1 className="large-heading">{majorIndustriesSection?.title}</h1>
          {majorIndustriesSection?.description && majorIndustriesSection?.description.split('<br>').map((des,i)=>(
                      <p className='small-text' key={i}>{des}</p>
          ))}
          <div className='major-industries-div-container'>
            {industriesData.length>0 && [...industriesData].sort((a,b)=> a.priority - b.priority).map((item,index)=>(
              <MajorIndustryItem key={index} item={item}/>
            ))}
          </div>
      </div>
    </div>
    <CompanyList />
    <ContactSection />
    </div>
  )
}

export default Home