import React, { createContext, useContext, useState } from 'react';

const GeneralContext = createContext();

export const GeneralProvider = ({ children }) => {
  const [settings, setSettings] = useState({});
  const [contactList,setContactList] = useState(
    [
      {
        title: 'Hawk Marine Pte Ltd ',
        line1: '50 Tuas Avenue 11,#02-32 Tuas Lot',
        line2: '',
        city: 'Singapore',
        postcode: '639107',
        country: 'Singapore',
      },
      {
        title: 'Redhawk Engineering Consultancy',
        line1: '306, Embassy Square, 148 Infantry Road, Vasanth Nagar',
        line2: '',
        city: 'Bengaluru',
        postcode: '560001',
        country: 'India',
      },{
        title: 'PT Hawk Marine Indonesia',
        line1: 'Jalan Beringin, Lot 334, Batamindo Industrial Park, Muka Kuning',
        line2: '',
        city: 'Batam',
        postcode: '29433',
        country: 'Indonesia',
      },{
        title: 'IFAS Fire & Safety Sdn. Bhd ',
        line1: '#2511, Suite 25.03A, Johor Bahru City Square Office Tower ',
        line2: '106-108 Jalan Wong Ah Fook JB',
        city: 'Sentral Johor ',
        postcode: '80000',
        country: 'Malaysia ',
      },
    ]
  )
  const [isScrollToTopVisible,setScrollToTopVisibility] = useState(false)

  return (
    <GeneralContext.Provider value={{ settings, setSettings , isScrollToTopVisible , setScrollToTopVisibility , contactList , setContactList }}>
      {children}
    </GeneralContext.Provider>
  );
};

export const useGeneralContext = () => {
  const context = useContext(GeneralContext);

  if (!context) {
    throw new Error("useGeneralContext must be used within a GeneralProvider");
  }

  return context;
};
