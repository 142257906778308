import React, { useEffect, useRef, useState } from 'react'
import '../../assets/css/industries.css'
import headImage from '../../assets/image/industriesImage.webp'
import datacenter from '../../assets/image/industries/dataCenter.webp';
import lngImage from '../../assets/image/industries/LNG.webp'
import marineImage from '../../assets/image/industries/marineImage.webp'
import offshoreImage from '../../assets/image/industries/offshore.webp'
import powerGeneration from '../../assets/image/industries/powerGeneration.webp';
import miningPlant from '../../assets/image/industries/miningPlant.webp';
import offShoreWindTurbine from '../../assets/image/industries/offshoreWindTurbine.webp'
import { Arrow } from '../../assets/icons/iconIndex';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import useOnScreen from '../Common/utils/useOnScreen';
import { getIndustriesData, getPageHeaderWithName } from '../../API/services';
import config from '../../API/configaration';

function Industries() {
    const location = useLocation();
    const [headerData,setHeaderData] = useState({
        title: "OUR MAJOR INDUSTRIES",
        image: headImage
      })
    const [activeIndex, setActiveIndex] = useState(0);
    const [cardData,setCardData] = useState([
                        {
                            id:'data-center',
                            heading:'data center',
                            image:datacenter,
                            sub_heading:'Need a fire fighting system that allows you to keep your data centre running while suppressing a fire?',
                            description:"At Hawk Marine Fire & Gas Solutions, we understand that data centers are vital to today’s digital landscape and demand strong safety measures to protect their operations. Our advanced fire and gas detection systems are built to safeguard your valuable assets while keeping your operations running smoothly. With years of experience in high-risk environments, our skilled team provides personalized solutions to address the specific challenges of data centers. In high-risk places like data centers, protection of valuable equipment and expensive data is of prime importance. Our fire protection solution will ensure continued operation of your data center by effectively suppressing the fires. <br> Our advanced systems provide immediate cooling in fire zones while prioritizing the safety of personnel, ensuring that ventilation continues to support optimal conditions. Water mist fire protection, gaseous fire protection systems, and the most modern fire detection and control systems are available to our clients. We collaborate with South-East Asia's and international leaders to ensure that your systems are safe and secure, minimizing the risks of fires.",
                            specification_content:[
                                {heading:'challenge',description:'Protecting business critical information, business continuity and people'},
                                {heading:'Application',description:'Protecting enclosed spaces with minimal environmental impact through rapid detection and system actuation.'},
                                {heading:'Key Hazards',point:'Electrical Rooms<br> Telecommunications<br> Backup Battery Rooms <br> Computer Rooms <br> Data Cabinets <br> Tape storage Libraries'},
                                {heading:'Our Solutions',point:'Water mist Fire protection <br> Gaseous Fire Protection system <br> Fire Detection & Control System '},
                            ]
                        },
                        {
                            id:'lng-process-plant',
                            heading:'LNG Process Plant',
                            image:lngImage,
                            description:"Hawk Marine understands the specialized fire protection needs of LNG (Liquefied Natural Gas) process plants. Due to the extremely high flammability of the product and complicated processes, safety is of utmost importance. Our services are tailored to protect your facility, protect people, and ensure smooth operation. We provide innovative safety systems for LNG operations, with a strong emphasis on reliable protection Our fire suppression systems are well designed with the LNG industry focusing on quick detection and fast reaction. <br> We have highly advanced products, each focusing on specific hazards related to the processing of LNG, whether it is from storage tanks to processing units. We believe time is money, and hence our systems are designed to minimize downtime with maximum safety. We closely engage with you to assess your facility's distinct needs and provide tailored solutions that comply with industry standards. Hawk Marine is here for you every step of the way, from initial consultation to installation and continuous maintenance.",
                            specification_content:[
                                {heading:'challenge',description:'LNG process plants face unique fire hazards, requiring specialized safety measures and rapid response solutions.'},
                                {heading:'Application',description:'Providing energy security, reducing greenhouse gas emissions, and supporting the transition to cleaner fuel sources.'},
                                {heading:'Key Hazards',description:'Control Rooms<br> Conveyors <br> Electrical Areas <br> Flammable Liquid/Chemical Storage  <br> Generator Rooms <br> Machinery and Mechanical Rooms'},
                                {heading:'Our Solutions',description:' Gaseous Fire Suppression system <br> Fire Detection & Control System'},
                            ]
                        },
                        {
                            id:'marine',
                            heading:'Marine',
                            image:marineImage,
                            sub_heading:"What fire suppression solutions offer to ensure safety in the marine industry?",
                            description:"Self-sufficiency is important when you're away from shore. Hawk Marine solutions are designed to have a low environmental impact while prioritizing safety. Our innovative approach helps you keep your operations strong and your crew protected. The marine sector faces the important challenge of securing assets, maintaining continuity, and prioritizing crew safety. Fires at sea are particularly dangerous in terms of catastrophic property and human life losses. Our advanced fire suppression technologies actually mitigate these risks.<br> At Hawk Marine, our expertise lies in high-performance fire suppression solutions tailored for the maritime world. Our systems are engineered to ensure fast detection and immediate actuation to deal with any potential fire before it poses serious danger. Our solutions, designed to protect the engine room, galley, or helipad, are designed to overcome the challenges of maritime operations. ",
                            specification_content:[
                                {heading:'challenge',description:'Protecting your property, ensuring operational continuity, and safeguarding your team.'},
                                {heading:'Application',description:'Securing indoor areas by quickly detecting threats while keeping environmental impact to a minimum.'},
                                {heading:'Key Hazards',description:'Battery Room Cargo<br> Spaces Control Room <br> Cooking Equipment/Galley  <br> Electrical Areas  <br> Liquids/Chemical Storage  <br> Generator Rooms <br> Heliports and Helidecks <br>Machinery and Mechanical Rooms <br> Offshore Platforms Pump <br> Rooms <br> Telecommunications'},
                                {heading:'Our Solutions',description:'Water mist Fire protection <br> Gaseous Fire Suppression system <br> Foam Agent Fire suppression system <br> Dry Chemical suppression system <br> Fire Detection & Control System'},
                            ]
                        },
                        {
                            id:'offshore-and-oil&gas',
                            heading:'Offshore and Oil & Gas',
                            image:offshoreImage,
                            sub_heading:"What fire protection strategies can we implement to safeguard your offshore and oil & gas facilities?",
                            description:"Oil and gas facilities pose a greater risk of fire hazards. Offshore oil platforms, pumping stations, refineries, gasoline storage tanks, compressor stations, gas processing plants, and LNG receiving facilities are several examples of sites that pose great fire hazards, particularly due to extreme conditions and remote locations. These risks can effectively be mitigated only with the application of specialized fire suppression systems. Hawk Marine understands the volatile nature of flammable liquids and can provide protection. <br>Our specialty in the oil and gas industry enables us to tackle the challenges of safeguarding your pumping stations, refineries, storage tanks, machinery, assets, and personnel. We focus on high-risk enclosed space safety, utilizing quick detection and response systems that reduce environmental impact. Our solutions are focused on the rapid detection of high-risk enclosed spaces, along with prompt system actuation for their safety. We utilize the latest technology with proven methodologies to develop systems that offer not only asset protection but environmental benefit as well.  ",
                            specification_content:[
                                {heading:'challenge',description:'Securing your pumping stations, refineries, storage tanks, machinery, assets, and personnel.'},
                                {heading:'Application',description:'Ensuring the safety of high-risk enclosed spaces with rapid detection and minimal environmental impact.'},
                                {heading:'Key Hazards',description:'Offshore Oil Platform<br> Pumping Station <br> Bulk Storage  <br> Control Rooms <br> Electrical Areas  <br> Flammable Liquids/Chemical Storage <br> Generator Rooms <br>Laboratories <br> Loading Racks <br> Pump Rooms <br> Storage Tanks'},
                                {heading:'Our Solutions',description:'Water mist Fire protection <br> Gaseous Fire Suppression system <br> Foam Agent Fire suppression system <br> Dry Chemical suppression system <br> Fire Detection & Control System'},   
                            ]
                        },
                        {
                            id:'power-generation',
                            heading:'Power Generation',
                            image:powerGeneration,
                            sub_heading:"What fire safety solutions do you offer to protect power generation facilities from potential fire hazards?",
                            description:"Fires in power generation plants can lead to serious consequences for your team, your business, and your customers. At Hawk Marine, we understand the importance that a fire protection system assumes in the power generation industry. We prioritize systems that ensure fast detection and swift response, reducing downtime and enhancing safety. Power plants are one of the biggest fire risks because they have so many different kinds of fire hazards: flammable fuels plus moving machinery. <br>Given the substantial capital investment in each facility, a fire not only poses a threat to the physical aspect but also to business. Downtime puts a daily electricity user at extreme risk. Our advanced fire suppression technologies are designed for highly hazardous environments, making sure even the most challenging spaces are covered. Whatever the protection of turbine rooms, control centers, or fuel storage areas, you need a solution you can rely on to ensure your business runs smoothly. Hawk Marine has a complete focus on integrated solutions to secure your operations and people.<br> ",
                            specification_content:[
                                {heading:'challenge',description:'Protecting your assets and operations while safeguarding your people without interrupting the supply of electricity in the event of fire.'},
                                {heading:'Application',description:'Protecting highly hazardous spaces with minimal downtime through rapid detection and system actuation.'},
                                {heading:'Key Hazards',description:'Control Rooms<br> Conveyors <br> Electrical Areas  <br> Flammable Liquid/Chemical Storage <br> Generator Rooms  <br> Machinery and Mechanical Rooms <br> Pump Rooms <br>Turbine Generator'},
                                {heading:'Our Solutions',description:'Gaseous Fire Suppression system <br> Fire Detection & Control System'},   
                            ]
                        },
                        {
                            id:'mining-process-plant',
                            heading:'Mining Process Plant',
                            image:miningPlant,
                            sub_heading:"What specialized fire protection services do you provide for mining process plants to address unique fire hazards?",
                            description:"Fire is one of the most extreme hazards to the mining industry. Surface mining and underground mining present different types of challenges to fire protection because of the often brutal, remote environments they operate in. At Hawk Marine, we have an impressive portfolio of fire protection products developed specifically for mining operations. Our systems can treat a variety of fire hazards, from electrical fires and hydrocarbon risks to debris-related incidents. We will protect shovels, loaders, conveyors, or even the computerized control rooms - these solutions are customized for every piece of equipment and location within your plant process.<br>You need a fire suppression system that provides safety for your crew, protects your valuable assets, and keeps your mine operational. From the smallest loader to the largest draglines and excavators, Hawk Marine has expertise and technology to meet all your fire protection needs. With large off-road vehicles and powerful processing equipment running around the clock, safety needs to be a constant concern.",
                            specification_content:[
                                {heading:'challenge',description:'Protecting your equipment secure and mine operating while safeguarding your people'},
                                {heading:'Application',description:'Protecting highly hazardous equipment, vehicles, and spaces through rapid detection and system actuation.'},
                                {heading:'Key Hazards',description:'Non-Road <br> Mobile  <br> Equipment <br> Control Rooms <br> Conveyors  <br> Electrical Areas <br> Flammable Liquid/Chemical Storage <br>Generator Rooms <br> Machinery and Mechanical Rooms'},
                                {heading:'Our Solutions',description:'Gaseous Fire Suppression system <br>Foam Agent Fire suppression system <br> Dry Chemical suppression system <br> Fire Detection & Control System'},   
                            ]
                        },
                        {
                            id:'offshore-wind-turbine',
                            heading:'Offshore Wind Turbine',
                            image:offShoreWindTurbine,
                            sub_heading:"What innovative solutions provide to secure the efficiency of offshore wind turbine operations?",
                            description:"Hawk Marine is focused on ensuring that these vital systems work safely and efficiently, creating a greener tomorrow. Offshore wind turbines are quite critical in renewable energy, utilizing wind above oceans and big lakes to generate clean, sustainable electricity. Secure offshore wind operations with reliable safety for renewable energy. Considering that the marine conditions are quite difficult and installation and maintenance of offshore wind turbines is a pretty complex process, safety is quite important in offshore wind turbine operations. <br> Offshore Wind Turbines are huge structures; their blades convert the energy from the wind into electricity. They are deployed in marine areas where the strength and consistency of the winds are much stronger compared to those of the onshore turbines, thus producing more electricity. Safety is a top priority at Hawk Marine, where we use strong protocols to protect our personnel and equipment, ensuring secure operations from the start of installation to the end of decommissioning.",
                            specification_content:[
                                {heading:'challenge',description:'Managing difficult marine conditions and complex logistics while ensuring safety, efficiency, and minimal harm to the environment.'},
                                {heading:'Application',description:'Prioritizing safety in high-risk confined areas and also  protecting the environment'},
                                {heading:'Key Hazards',description:'Offshore Oil Platform <br> Pumping Station  <br> Bulk Storage <br> Control Rooms <br> Electrical Areas <br> Flammable Liquid/Chemical Storage <br>Pump Rooms <br> Storage Tanks'},
                                {heading:'Our Solutions',description:'Water mist Fire protection <br>Gaseous Fire Suppression system <br>Foam Agent Fire suppression system <br> Dry Chemical suppression system <br> Fire Detection & Control System'},   
                            ]
                        }
                    ])
    useEffect(()=> {
        const pathParts = location.pathname.split('/');
        const slug = pathParts[pathParts.length - 1];
        const index = cardData.findIndex(card => card.id === slug);
        if(index !== -1){
            setActiveIndex(index);
            autoScroll(1100)
        }else{
            setActiveIndex(0);
        }
    },[location.pathname])
    const handleCardClick = (index) => {
        setActiveIndex(index);
        autoScroll(400)
    };
    const autoScroll = (time) => {
        setTimeout(() => {
            const element = document.getElementById('redirect-section');
            if (element) {
              const elementRect = element.getBoundingClientRect();
              const offsetTop = window.pageYOffset + elementRect.top - 110; // 80px offset from the top
              window.scrollTo({
                top: offsetTop,
                behavior: 'smooth',
              });
            }
          }, time);
    }
    useEffect(()=>{
        getPageHeaderWithName('industries').then((res)=>{
            const  data = res.data[0];
            data.image = config.apiBaseUrl + data.image
            setHeaderData(data)
          }).catch((error)=>{
            
          })
        getIndustriesData().then((res)=>{
            const data = res.data;
            setCardData(data)
        }).catch((error)=>{

        })
    },[])
    // animation
    const divRef = useRef();
    const visible1 = useOnScreen(divRef);
  return (
    <>
      <Helmet>
        <title>Industries</title>
        <meta name='description' content="Hawk Marine's Fire & Gas Detection Systems features advanced sensors that monitor combustible and toxic gases and detect fire, heat, and smoke to secure industrial facilities" />
        <meta name='keywords' content="hawk marine,hawkmarine,fire and saftey,cctv,fire Suppression system,Fire & Gas Detection System,data center,LNG Process Plant,Marine,Offshore, and ,Oil & Gas,Mining Process Plant,Offshore Wind Turbine"/>
      </Helmet>
    <div className="industries-first-div scroll-ref" style={{backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${headerData?.image})`}}>
        <h1 className="large-heading">{headerData?.title}</h1>
    </div>
    <div className="industries-content-div">
            <div className='container'>
                <div className="industries-content-card-container">
                    {cardData?.map((item,index) => (
                    <div key={index} className={`industries-content-card`} style={{backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(${config.apiBaseUrl + item?.image})`}}
                    onClick={() => handleCardClick(index)}>
                        <div className={`card-content ${activeIndex === index ? 'active' : ''}`}>
                            <h2 className="card-heading">{item.title}</h2>
                            <div className="read-more-industrie-card">Read more <Arrow/></div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
            {cardData.length > 0 && (
                <div className='container'>
                    <div id='redirect-section' className="industries-selected-content">
                        <h1 className='large-heading'>{cardData[activeIndex]?.title}</h1>
                        <div className="industries-content" dangerouslySetInnerHTML={{__html: cardData[activeIndex]?.description}}>
                        </div>
                        {/* {cardData[activeIndex]?.sub_heading?.split('<br>').map((item,index)=>(
                            <h2 className="sub-large-heading" key={index}>{item}</h2>
                        ))}
                        {cardData[activeIndex]?.description?.split('<br>').map((desc,i)=>(
                            <p className="small-text paragraph" key={i}>{desc}</p>
                        ))} */}
                    </div>
                    {cardData[activeIndex]?.industry_content?.length > 0 &&(
                    <div ref={divRef} className={`industries-selected-content-table ${visible1 ? 'aos-animate' : 'aos-init'}`}>
                        {cardData[activeIndex].industry_content?.map((content,contentIndex) => (
                            <div className="content-table-item" key={contentIndex}>
                            <h3 className="content-heading">{content.title}:</h3>
                            <div className='content-description' dangerouslySetInnerHTML={{__html: content.description}}>
                                {/* {content.description && content.description.split('<br>').map((description,descriptionIndex) =>(
                                    <span key={descriptionIndex}>{description}</span>
                                ))}
                                {content.point && (
                                    <ul style={{listStyleType:'none'}}>
                                        {content.point.split('<br>').map((point,k)=>(
                                            <li style={{marginTop:'6px'}} key={k}>{point}</li>
                                        ))}                                   
                                    </ul>
                                )} */}
                            </div>
                            </div>
                        ))}
                </div>
                    )}

                </div>
            )}
        </div>
    </>
  )
}

export default Industries