import React, { useEffect, useState } from 'react'

import logo from '../assets/image/Group430.png'
import {  ArrowUp, LinkedIn2 } from '../assets/icons/iconIndex'
import { useNavigate } from 'react-router-dom'
import { getIndustriesData, getSolutionList } from '../API/services'
function Footer() {
    const naviagte = useNavigate()
    const [solutionList,setSolutionList] = useState(
        [
            {path:'fire-and-gas-detection-system',title:'Fire & Gas Detection System'},
            {path:'high-pressure-water-mist-system',title:"High-Pressure Water Mist System"},
            {path:'halocarbon-clean-agent-fire-suppression-system',title:"Halocarbon Clean Agent Fire Suppression System"},
            {path:'cctv-system',title:"CCTV System"},
            {path:'foam-based-fire-suppression-system',title:"Foam-based Fire Suppression System"},
            {path:'inner-gas-suppression-system',title:"Inert Gas Suppression System"}
          ]
    )
    const [industryList,setIndustryList] = useState([
        {path:'data-center',title:'Data Center'},
        {path:'lng-process-plant',title:'LNG Process Plant'},
        {path:'marine',title:'Marine'},
        {path:'offshore-and-oil-gas',title:'Offshore and Oil & Gas'},
        {path:'power-generation',title:'Power Generation'},
        {path:'mining-process-plant',title:'Mining Process Plant'},
        {path:'offshore-wind-turbine',title:'Offshore Wind Turbine'},
    ])
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1200);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const triggerShake = () => {
        const svgElement = document.querySelector('.arrow-up-navigation-div svg');
        if (svgElement) {
            svgElement.style.animation = 'subtle-shake 1s ease-in-out';
            setTimeout(() => {
                svgElement.style.animation = '';
            }, 1000);
        }
    };
    useEffect(() => {
        const intervalId = setInterval(triggerShake, 5000); // Trigger shake every 5 seconds

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);
    useEffect(()=>{
        getSolutionList().then((res)=>{
            setSolutionList(res.data)
        }).catch((error)=>{

        })
        getIndustriesData().then((res)=>{
            setIndustryList(res.data)
        }).catch((error)=>{

        })
    },[])

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };


      
  return (
    <div className='footer-container'>
        <div className='sub-footer-div'>
            <div className='container'>
                <div className="sub-footer">
                    <div className="footer-logo" onClick={()=>naviagte('/')}>
                        {/* Replace `logo.png` with your actual logo path */}
                        <img src={logo} alt="Hawk" />
                    </div>
                    <div className="footer-links">
                        <div className="footer-column">
                        <div className='footer-column-heading'>Useful Links</div>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><a href="/about">About Us</a></li>
                            <li><a href="/references">References</a></li>
                            {/* <li><a href="#blogs">Blogs</a></li> */}
                            <li><a href="/contact-us">Contact Us</a></li>
                        </ul>
                        </div>
                        <div className="footer-column">
                        <div className='footer-column-heading'>Industries</div>
                        <ul>
                            {industryList.length > 0 && industryList.map((item,index)=>(
                                <li key={index}><a href={`/industries/${item.path}`}>{item.title}</a></li>
                            ))}
                        </ul>
                        </div>
                        <div className="footer-column">
                        <div className='footer-column-heading'>Social Media</div>
                        <div className="social-icons">
                            {/* <a href="#google" target="_blank" rel="noopener noreferrer"><Google/></a> */}
                            {/* <a href="" target="_blank" rel="noopener noreferrer"><Facebook2/></a> */}
                            <a href="https://www.linkedin.com/company/hawk-marine/" target="_blank" rel="noopener noreferrer"><LinkedIn2/></a>
                            {/* <a href="#twitter" target="_blank" rel="noopener noreferrer"><X/></a> */}
                        </div>
                        </div>
                        <div className="footer-column solution-footer">
                            <div className='footer-column-heading'>Solutions</div>
                            <div className="footer-columns">
                                {Array.from({ length: 3 }).map((_, colIndex) => (
                                    <ul key={colIndex}>
                                        {solutionList
                                            .slice(
                                                Math.floor(colIndex * solutionList.length / 3),
                                                Math.floor((colIndex + 1) * solutionList.length / 3)
                                            )
                                            .map((item, index) => (
                                                <li key={index}>
                                                    <a href={`/solutions/${item.path}`}>{item.title}</a>
                                                </li>
                                            ))}
                                    </ul>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="arrow-up-navigation-div">
                {/* <ArrowUp onClick={scrollToTop}/> */}
            </div>
        </div>
        <div className="credits-div">
            <div className='container'>
                {/* <div className="credits-part-one">
                    <p>Privacy Policy</p>
                    <p>Terms & Conditions</p>
                </div> */}
                <div className="credits-part-two">
                    <p>©2024 Hawk Marine. All Rights Reserved</p>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Footer